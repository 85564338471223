import { css } from "../../utils/css.js";

interface ClassNames {
  avatarWrapper: string;
}

export default css<ClassNames>`
  .avatarWrapper {
    position: relative;
    display: inline-block; /* Ensure it can contain pseudo-elements */
    z-index: 1;
  }

  .avatarWrapper::before,
  .avatarWrapper::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 10px;
    background: rgba(0, 0, 0, 1);
    z-index: -1;
  }

  .avatarWrapper::before {
    bottom: -10px;
    width: 80%;
    margin-left: 10%;
  }

  .avatarWrapper::after {
    bottom: -20px;
    width: 60%;
    margin-left: 20%;
  }
`;
