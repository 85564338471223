import { IntegerField } from "../types/field";

export const parentThreadIdField: IntegerField<{
  final: true;
  name: "parent_thread_id";
}> = {
  final: true,
  label: "Parent Thread ID",
  name: "parent_thread_id",
  type: "integer",
};
