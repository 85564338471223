import { useCallback, useEffect, useState } from "react";

import { UserEntity } from "../../common/entities/user.js";
import { userClient } from "../../common/utils/entityClient.js";
import { addUsersToCache } from "../components/Excelente/UserProfile.js";
import { useActiveStates } from "../contexts/ActiveStatesContext.js";
import {
  PennyConversation,
  PennyConversationState,
} from "../pages/penny/PennyConversation.js";
import { clientError } from "../utils/clientError.js";

export function AsideContent() {
  const [state, setState] = useState<PennyConversationState>({
    conversation: {
      id: "new" as unknown as number,
      threads: [],
      created_at: new Date(),
      updated_at: new Date(),
      title: "New Conversation",
    },
  });
  const { user, serviceProvider, customer } = useActiveStates();
  const organization = customer || serviceProvider;

  const [users, setUsers] = useState<UserEntity[]>([]);

  const fetchUsers = useCallback(async () => {
    const response = await userClient(clientError).list({
      organization_id: organization?.id,
    });
    if (response.success && "data" in response && "data" in response.data) {
      setUsers(response.data.data);
      addUsersToCache(response.data.data);
    }
  }, [organization?.id]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <PennyConversation
      state={state}
      setState={setState}
      user={user}
      users={users}
      organization={organization}
      customer={customer}
      serviceProvider={serviceProvider}
    />
  );
}
