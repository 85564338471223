import { Button, Card, Group, Stack, Text, Title } from "@mantine/core";
import { IconClock } from "@tabler/icons-react";

import classes from "./ArticleCardImage.css.js";

import { RecipeButton } from "../Buttons/RecipeButton.js";

interface ArticleCardImageProps {
  backgroundColor?: string;
  backgroundImage?: string;
  button?: JSX.Element | null;
  category?: string;
  comingsoon: boolean;
  id: number;
  link?: string;
  logo?: string;
  scope?: string;
  title: string;
}

export const ArticleCardImage = ({
  backgroundColor,
  button,
  category,
  comingsoon,
  id,
  logo,
  scope,
  title,
}: ArticleCardImageProps) => {
  return (
    <Card withBorder p="xl" radius="xs" className={classes.card}>
      <Card.Section>
        {" "}
        <div>
          {!logo ? (
            <>
              <Text className={classes.category} size="xs">
                {category}
              </Text>
              <Title order={3} className={classes.title}>
                {title}
              </Title>
            </>
          ) : (
            <img alt="logo" src={logo} width={150} height={70} />
          )}
        </div>
      </Card.Section>
      <Card.Section>
        <Stack gap="xs">
          <Title order={4}>{title}</Title>
          <Text>{scope}</Text>
        </Stack>
      </Card.Section>
      <Card.Section>
        <Group mt="xl">
          {button ? (
            button
          ) : !comingsoon ? (
            <RecipeButton id={id} />
          ) : (
            <Button
              variant="primary"
              leftSection={<IconClock />}
              color={backgroundColor}
            >
              Coming soon
            </Button>
          )}
        </Group>
      </Card.Section>
    </Card>
  );
};
