import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Button,
  Group,
  Loader,
  Menu,
  Modal,
  Paper,
  ScrollArea,
  Select,
  Stack,
  Table,
  Text,
  Title,
} from "@mantine/core";
import {
  IconAlertCircle,
  IconCircleArrowUpLeftFilled,
  IconDots,
  IconX,
} from "@tabler/icons-react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Link } from "react-router-dom";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { OrganizationRelationshipUserEntity } from "../../../common/entities/organizationRelationshipUser.js";
import {
  getUserName,
  userEntity,
  UserEntity,
} from "../../../common/entities/user.js";
import { EntitlementLabels } from "../../../common/fields/entitlements.js";
import { idField } from "../../../common/fields/id.js";
import { userIdFieldRequired } from "../../../common/fields/user_id.js";
import {
  organizationRelationshipUserClient,
  userClient,
} from "../../../common/utils/entityClient.js";
import { clientError } from "../../utils/clientError.js";
import { UserProfile } from "../Excelente/UserProfile.js";

interface Props {
  serviceProvider: OrganizationEntity;
  customer: OrganizationEntity;
  isCustomerUser: boolean;
}

export function OrganizationRelationshipUserManagement({
  serviceProvider,
  customer,
  isCustomerUser,
}: Props) {
  const [availableUsers, setAvailableUsers] = useState<UserEntity[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isLoadingAvailableUsers, setIsLoadingAvailableUsers] = useState(
    !isCustomerUser
  );
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isRemovingUserId, setIsRemovingUserId] = useState<number | undefined>(
    undefined
  );
  const [isRestoringUserId, setIsRestoringUserId] = useState<
    number | undefined
  >(undefined);
  const [relationshipUsers, setRelationshipUsers] = useState<
    OrganizationRelationshipUserEntity[]
  >([]);

  const availableAndNotAddedUsers = useMemo(() => {
    return availableUsers.filter(
      (user: UserEntity) =>
        !relationshipUsers.some(
          (u: OrganizationRelationshipUserEntity) =>
            u.user_id === user.id && !u.archived_at
        )
    );
  }, [availableUsers, relationshipUsers]);

  const availableAndNotAddedUsersLabels = useMemo(() => {
    return availableAndNotAddedUsers.map((user: UserEntity) => ({
      value: user.id?.toString() || "",
      label: getUserName(user) ?? user?.email ?? user?.id?.toString?.() ?? "",
    }));
  }, [availableAndNotAddedUsers]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        console.log("fetchUsers with options", {
          join: userEntity.name,
          from: idField.name,
          to: userIdFieldRequired.name,
        });
        const response = await organizationRelationshipUserClient(
          clientError
        ).list(
          {
            provider_id: serviceProvider.id,
            customer_id: customer.id,
          },
          {
            join: userEntity.name,
            from: idField.name,
            to: userIdFieldRequired.name,
          }
        );

        if (response.success && "data" in response && "data" in response.data) {
          setRelationshipUsers(response.data.data);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : "An error occurred");
      } finally {
        setIsLoadingUsers(false);
      }
    };

    fetchUsers();
  }, [serviceProvider.id, customer.id]);

  useEffect(() => {
    const fetchAvailableUsers = async () => {
      if (isCustomerUser) {
        return;
      }
      try {
        const response = await userClient(clientError).list({
          organization_id: serviceProvider.id,
        });

        if (response.success && "data" in response && "data" in response.data) {
          setAvailableUsers(response.data.data);
        }
      } catch (err) {
        console.error("Error fetching available users:", err);
      } finally {
        setIsLoadingAvailableUsers(false);
      }
    };

    fetchAvailableUsers();
  }, [serviceProvider.id]);

  const handleRemoveUser = async (user: OrganizationRelationshipUserEntity) => {
    console.log("Remove user", user);
    setIsRemovingUserId(user.user_id!);
    try {
      const response = await organizationRelationshipUserClient(clientError)
        .item(serviceProvider.id!, customer.id!, user.user_id!)
        .delete();

      if (response.success && response.data.success) {
        setRelationshipUsers(
          relationshipUsers.map((u) =>
            u.user_id !== user.user_id
              ? u
              : response.data.success
              ? response.data.data ?? u
              : u
          )
        );
      }
    } catch (err) {
      console.error("Error removing user", err);
    } finally {
      setIsRemovingUserId(undefined);
    }
  };

  const handleRestoreUser = async (
    user: OrganizationRelationshipUserEntity
  ) => {
    setIsRestoringUserId(user.user_id!);
    try {
      const response = await organizationRelationshipUserClient(clientError)
        .item(serviceProvider.id!, customer.id!, user.user_id!)
        .upsert({
          archived_at: null as unknown as undefined,
          customer_id: customer.id,
          entitlements: [] as unknown as number, // array types aren't correct
          provider_id: serviceProvider.id,
          user_id: user.user_id,
        });

      if (response.success && response.data.success) {
        setRelationshipUsers(
          relationshipUsers.map((u) =>
            u.user_id !== user.user_id ? u : { ...u, archived_at: undefined }
          )
        );
      }
    } catch (err) {
      console.error("Error restoring user", err);
    } finally {
      setIsRestoringUserId(undefined);
    }
  };

  const handleAddUser = async (user: UserEntity) => {
    console.log("Add user", user);
    setIsAddingUser(true);
    try {
      const response = await organizationRelationshipUserClient(clientError)
        .item(serviceProvider.id!, customer.id!, user.id!)
        .upsert({
          archived_at: null as unknown as undefined,
          customer_id: customer.id,
          entitlements: [] as unknown as number, // array types aren't correct
          provider_id: serviceProvider.id,
          user_id: user.id,
        });
      if (
        response.success &&
        "data" in response &&
        "data" in response.data &&
        response.data.success
      ) {
        setRelationshipUsers([...relationshipUsers, response.data.data]);
        setSelectedUser(undefined);
        setAddUserModalOpened(false);
      }
    } catch (err) {
      console.error("Error adding user", err);
    } finally {
      setIsAddingUser(false);
    }
  };

  const [selectedUser, setSelectedUser] = useState<UserEntity | undefined>(
    undefined
  );

  const setSelectedUserByIdString = useCallback(
    (value: string | null) => {
      if (typeof value === "string") {
        const foundUser = availableUsers.find(
          (u) => u.id?.toString(10) === value
        );
        setSelectedUser(foundUser);
      } else if (value === null) {
        setSelectedUser(undefined);
      }
    },
    [availableUsers]
  );

  const [addUserModalOpened, setAddUserModalOpened] = useState(false);

  if (error) {
    return (
      <Alert
        icon={<IconAlertCircle size={16} />}
        title="Error"
        color="red"
        variant="filled"
      >
        {error}
      </Alert>
    );
  }

  const isLoading =
    isLoadingUsers || (isLoadingAvailableUsers && !isCustomerUser);

  const selectedUserExistingRelationship = relationshipUsers.find(
    (u) => u.user_id === selectedUser?.id
  );

  return (
    <Stack gap="md">
      <Box py="md" px="lg">
        <Group justify="space-between" align="center">
          <Title
            order={4}
          >{`${serviceProvider.name} users that have access to ${customer.name}`}</Title>
          {!isCustomerUser ? (
            <Button onClick={() => setAddUserModalOpened(true)}>
              Add User
            </Button>
          ) : null}
        </Group>
        <Text mt="lg">
          These people will have access to the client's resources, including the
          client's QuickBooks Online account.
        </Text>
      </Box>

      <Modal
        opened={addUserModalOpened}
        onClose={() => setAddUserModalOpened(false)}
        title="Add User"
      >
        <Modal.Body>
          <Stack gap="md">
            <Text>
              Select a {serviceProvider.name} organization member to add to the
              relationship between service provider {serviceProvider.name} and
              client {customer.name}.
            </Text>
            <Text>
              Users with existing permissions are not displayed in the user
              selector.
            </Text>
            {availableAndNotAddedUsers?.length > 0 ? (
              <Select
                clearable
                data={availableAndNotAddedUsersLabels}
                disabled={isAddingUser}
                onChange={setSelectedUserByIdString}
                placeholder="Select user to add..."
                searchable
                value={selectedUser?.id?.toString()}
              />
            ) : (
              <>
                <Text>
                  No users without existing access found. If you're trying to
                  add a new user to your team, click the button below to add a
                  new user.
                </Text>
                <Link
                  to={"/people/overview"}
                  onClick={() => {
                    setAddUserModalOpened(false);
                    alert(
                      "Switch to your organization in the top left to add a new user"
                    );
                  }}
                >
                  <Button>Add {serviceProvider.name} User</Button>
                </Link>
              </>
            )}
            {selectedUser && (
              <UserProfile
                withAvatar
                withArchived
                user={selectedUserExistingRelationship ?? selectedUser}
              />
            )}
            <Button
              disabled={
                !selectedUser || isAddingUser || isRestoringUserId !== undefined
              }
              onClick={async () => {
                if (selectedUserExistingRelationship) {
                  await handleRestoreUser(selectedUserExistingRelationship);
                } else {
                  await handleAddUser(selectedUser!);
                }
                setSelectedUser(undefined);
                setAddUserModalOpened(false);
              }}
            >
              {isAddingUser || isRestoringUserId !== undefined ? (
                <>
                  <Loader size="xs" />
                  {selectedUserExistingRelationship
                    ? "Restoring..."
                    : "Adding..."}
                </>
              ) : selectedUserExistingRelationship ? (
                "Restore User"
              ) : (
                "Add User"
              )}
            </Button>
          </Stack>
        </Modal.Body>
      </Modal>

      <Paper shadow="xs" pos="relative">
        {relationshipUsers.length === 0 && !isLoading ? (
          <Text c="dimmed" p="lg">
            No {serviceProvider.name} users found for this relationship.
          </Text>
        ) : (
          <ScrollArea mah={`calc(100vh - 140px)`}>
            <Table striped highlightOnHover verticalSpacing="sm">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th pl="lg">User</Table.Th>
                  <Table.Th>Status</Table.Th>
                  <Table.Th>Entitlements</Table.Th>
                  {!isCustomerUser ? <Table.Th></Table.Th> : null}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {relationshipUsers.map((user) => (
                  <Table.Tr key={user.user_id}>
                    <Table.Td pl="lg">
                      <Group gap="sm">
                        <UserProfile withAvatar withArchived user={user} />
                      </Group>
                    </Table.Td>
                    <Table.Td>
                      <Badge
                        color={user.archived_at ? "red" : "green"}
                        variant="light"
                      >
                        {user.archived_at ? "No Access" : "Access Granted"}
                      </Badge>
                    </Table.Td>
                    <Table.Td>
                      {user.entitlements && user.entitlements > 0 ? (
                        (user.entitlements as unknown as number[]).map(
                          (entitlement: number) => (
                            <Badge key={entitlement} variant="light" mr={4}>
                              {EntitlementLabels[
                                entitlement as keyof typeof EntitlementLabels
                              ] ?? entitlement}
                            </Badge>
                          )
                        )
                      ) : (
                        <Text size="sm" c="dimmed">
                          No entitlements
                        </Text>
                      )}
                    </Table.Td>
                    {!isCustomerUser ? (
                      <Table.Td>
                        {isRemovingUserId !== user.user_id &&
                        isRestoringUserId !== user.user_id ? (
                          <Menu position="bottom-end" withArrow>
                            <Menu.Target>
                              <ActionIcon variant="subtle" color="gray">
                                <IconDots
                                  style={{ width: "70%", height: "70%" }}
                                />
                              </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                              {user.archived_at ? (
                                <Menu.Item
                                  leftSection={
                                    <IconCircleArrowUpLeftFilled size={14} />
                                  }
                                  color="orange"
                                  onClick={() => {
                                    handleRestoreUser(user);
                                  }}
                                >
                                  Restore Access
                                </Menu.Item>
                              ) : (
                                <Menu.Item
                                  leftSection={<IconX size={14} />}
                                  color="red"
                                  onClick={() => {
                                    handleRemoveUser(user);
                                  }}
                                >
                                  Remove
                                </Menu.Item>
                              )}
                            </Menu.Dropdown>
                          </Menu>
                        ) : (
                          <Group gap="xs" justify="center">
                            <Loader size="xs" />
                            {isRestoringUserId === user.user_id
                              ? "Restoring..."
                              : isRemovingUserId === user.user_id
                              ? "Removing..."
                              : "Loading..."}
                          </Group>
                        )}
                      </Table.Td>
                    ) : null}
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </ScrollArea>
        )}
        {isLoading ? (
          <Box
            style={{
              alignContent: "flex-end",
              alignItems: "center",
              display: "flex",
              height: "fit-content",
              justifyContent: "center",
              overflowY: "auto",
              padding: "100px",
            }}
          >
            <Loader />
          </Box>
        ) : null}
      </Paper>
    </Stack>
  );
}
