import { StringField } from "../types/field";

export const iconField: StringField<{
  name: "icon";
  required: true;
}> = {
  label: "Icon",
  name: "icon",
  required: true,
  type: "string",
};
