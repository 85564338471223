import { css } from "../utils/css.js";

interface ClassNames {
  collapsed: "collapsed";
  expanded: "expanded";
  content: string;
  wrapper: string;
}

export default css<ClassNames>`
  .wrapper {
    align-items: center;
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    left: 50%;
    overflow: visible;
    position: fixed;
    top: 1rem;
    transform: translateX(-50%);
    transition: height 0.5s ease, width 0.5s ease, opacity 1.5s ease-out;
    z-index: 300;
  }

  .wrapper.!collapsed {
    opacity: 0.35;
  }

  .wrapper.!collapsed:hover {
    opacity: 0.85;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    width: 100%;
  }
`;
