import { css } from "../../utils/css.js";

interface ClassNames {
  avatarWrapper: string;
}

export default (shadowColor: string) => css<ClassNames>`
  .avatarWrapper {
    position: relative;
    display: inline-block; /* Ensure it can contain pseudo-elements */
    z-index: 1;
  }

  .avatarWrapper::before,
  .avatarWrapper::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 4px;
    z-index: -1;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: ${shadowColor};
  }

  .avatarWrapper::before {
    bottom: -4px;
    width: 90%;
    margin-left: 5%;
    opacity: 0.4;
  }

  .avatarWrapper::after {
    bottom: -8px;
    width: 80%;
    margin-left: 10%;
    opacity: 0.2;
  }
`;
