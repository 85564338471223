import { useCallback, useEffect, useMemo, useRef } from "react";

import { MessageEntity } from "../../common/entities/message.js";
import { UserEntity } from "../../common/entities/user.js";
import {
  messageClient,
  messagesLastReadClient,
} from "../../common/utils/entityClient.js";
import { useKeyedState } from "./useKeyedState.js";
import { clientError } from "../utils/clientError.js";
import { MESSAGE_LIBRARY } from "../../common/fields/message.js";

export interface MessageControl {
  activeMessage: MessageEntity | undefined;
  activeMessageIndex: number | undefined;
  archiveMessage: (message: MessageEntity) => Promise<void>;
  focusMessage: (message: MessageEntity) => void;
  loadMessages: (
    userId: number,
    customerId?: number,
    providerId?: number
  ) => Promise<void>;
  loadingMessages: boolean;
  loadMessagesError?: string[];
  filter: { message?: keyof typeof MESSAGE_LIBRARY | null };
  setFilter: (filter: {
    message?: keyof typeof MESSAGE_LIBRARY | null;
  }) => void;
  markAsRead: (
    message: MessageEntity,
    userId: number,
    unread?: boolean
  ) => Promise<void>;
  markingAsRead: boolean;
  messages: MessageEntity[];
  readTimestamp: number;
  rescindActiveMessage: (replaceMessage?: MessageEntity) => void;
  setReadTimestamp: (timestamp: number) => void;
  unreadCount: number;
}

export function useMessages(
  user?: UserEntity,
  providerId?: number,
  customerId?: number
): MessageControl {
  const stateKey = `${user?.id ?? ""}-${providerId ?? ""}-${customerId ?? ""}`;
  const [activeMessageIndex, setActiveMessageIndex] = useKeyedState<
    number | undefined
  >(stateKey, undefined);
  const [messages, setMessages] = useKeyedState<MessageEntity[]>(stateKey, []);
  const [readTimestamp, setReadTimestamp] = useKeyedState<number>(stateKey, 0);
  const [loadingMessages, setLoadingMessages] = useKeyedState<boolean>(
    stateKey,
    false
  );
  const [loadMessagesError, setLoadMessageErrors] = useKeyedState<
    string[] | undefined
  >(stateKey, undefined);
  const [markingAsRead, setMarkingAsRead] = useKeyedState<boolean>(
    stateKey,
    false
  );
  const [filter, _setFilter] = useKeyedState<{
    message?: keyof typeof MESSAGE_LIBRARY | null;
  }>(stateKey, {
    message: null,
  });

  const setFilter = useCallback(
    (filter: { message?: keyof typeof MESSAGE_LIBRARY | null }) => {
      _setFilter(filter);
    },
    [_setFilter]
  );

  const currentFilter = useRef<{
    message?: keyof typeof MESSAGE_LIBRARY | null;
  }>(filter);

  useEffect(() => {
    currentFilter.current = filter;
  }, [filter]);

  const activeMessage = useMemo(() => {
    return activeMessageIndex !== undefined
      ? messages[activeMessageIndex]
      : undefined;
  }, [messages, activeMessageIndex]);

  const loadMessages = useCallback(
    async function (
      latestUserId: number,
      latestProviderId?: number,
      latestCustomerId?: number,
      latestFilter?: { message?: keyof typeof MESSAGE_LIBRARY | null }
    ) {
      if (!latestUserId) {
        return;
      }
      if (!latestCustomerId && !latestProviderId) {
        return;
      }
      const errors: string[] = [];
      try {
        setLoadMessageErrors(undefined);
        setLoadingMessages(true);
        const [response, timestampResponse] = await Promise.all([
          messageClient(clientError).list({
            provider_id: latestProviderId,
            customer_id: latestCustomerId,
            ...(typeof latestFilter?.message === "number"
              ? { message: latestFilter.message }
              : {}),
          }),
          messagesLastReadClient(clientError).list({
            customer_id: latestCustomerId,
            provider_id: latestProviderId,
            user_id: latestUserId,
          }),
        ]);
        if (latestFilter?.message !== currentFilter.current?.message) {
          return;
        }
        if (response.success) {
          if (response.data.success) {
            setMessages(
              response.data.data
                .map((m) => ({
                  ...m,
                }))
                .reverse() ?? []
            );
            setActiveMessageIndex(
              response.data.data.length > 0 ? 0 : undefined
            );
          } else {
            errors.push(...response.data.errors);
          }
        } else {
          errors.push(`HTTP ${response.status} ${response.error}`);
        }
        if (timestampResponse.success) {
          if (timestampResponse.data.success) {
            setReadTimestamp(
              new Date(
                timestampResponse.data.data?.[0]?.timestamp ?? 0
              ).getTime()
            );
          } else {
            errors.push(...timestampResponse.data.errors);
          }
        } else {
          errors.push(
            `HTTP ${timestampResponse.status} ${timestampResponse.error}`
          );
        }
      } finally {
        setLoadMessageErrors(
          errors.length > 0 ? [...new Set(errors)] : undefined
        );
        setLoadingMessages(false);
      }
    },
    [
      setActiveMessageIndex,
      setLoadingMessages,
      setLoadMessageErrors,
      setMessages,
      setReadTimestamp,
    ]
  );

  useEffect(() => {
    loadMessages(user?.id ?? 0, providerId, customerId, filter);
  }, [loadMessages, user?.id, providerId, customerId, filter?.message]);

  const nextMessage = useCallback(
    async function (direction?: -1) {
      const newMessageIndex =
        typeof activeMessageIndex === "number"
          ? activeMessageIndex + (direction ?? 1)
          : direction === -1
          ? messages.length - 1
          : 0;
      const message: MessageEntity | undefined = messages[newMessageIndex];
      if (!message) {
        return;
      }
      if (activeMessage?.id === message.id) {
        return;
      }
      setActiveMessageIndex(newMessageIndex);
    },
    [messages, activeMessageIndex, setActiveMessageIndex, activeMessage?.id]
  );

  const rescindActiveMessage = useCallback(
    function (replaceMessage?: MessageEntity) {
      if (replaceMessage) {
        setActiveMessageIndex(
          messages.findIndex((x) => x.id === replaceMessage?.id)
        );
      } else {
        setActiveMessageIndex(undefined);
      }
    },
    [messages, setActiveMessageIndex]
  );

  const focusMessage = useCallback(
    function (message: MessageEntity) {
      if (activeMessage?.id === message.id) {
        return;
      }
      setActiveMessageIndex(messages.findIndex((x) => x.id === message.id));
    },
    [activeMessage, messages, setActiveMessageIndex]
  );

  const markAsRead = useCallback(
    async function (message: MessageEntity, user_id: number, unread?: boolean) {
      if (!message.timestamp) {
        return;
      }
      setMarkingAsRead(true);
      const delta = unread ? -1000 : 1000;
      const timestamp = new Date(
        new Date(message.timestamp).getTime() + delta
      ).toISOString();
      try {
        await messagesLastReadClient(clientError)
          .item(user_id, providerId ?? null, message.customer_id ?? null)
          .upsert({
            timestamp,
            user_id,
            provider_id: providerId,
            customer_id: message.customer_id,
          });
        setReadTimestamp(new Date(timestamp).getTime());
      } catch (error) {
        console.error(error);
      } finally {
        setMarkingAsRead(false);
      }
    },
    [setReadTimestamp, setMarkingAsRead, providerId]
  );

  const unreadCount = useMemo(() => {
    return messages.filter(
      (x) =>
        !x.archived_at &&
        x.timestamp &&
        new Date(x.timestamp).getTime() > readTimestamp
    ).length;
  }, [messages, readTimestamp]);

  async function archiveMessage(message: MessageEntity) {
    const archiveResponse = await messageClient(clientError)
      .item(message.id!)
      .delete();
    if (!archiveResponse.success || !archiveResponse.data.success) {
      throw new Error("Failed to archive message");
    }
  }

  const finalMessageControl: MessageControl = useMemo(() => {
    return {
      activeMessage,
      activeMessageIndex,
      archiveMessage,
      filter,
      focusMessage,
      loadingMessages,
      loadMessages,
      loadMessagesError,
      markAsRead,
      markingAsRead,
      messages,
      nextMessage,
      readTimestamp,
      rescindActiveMessage,
      setFilter,
      setReadTimestamp,
      unreadCount,
    };
  }, [
    activeMessage,
    activeMessageIndex,
    archiveMessage,
    filter,
    focusMessage,
    loadingMessages,
    loadMessages,
    loadMessagesError,
    markAsRead,
    markingAsRead,
    messages,
    nextMessage,
    readTimestamp,
    rescindActiveMessage,
    setFilter,
    setReadTimestamp,
    unreadCount,
  ]);

  return finalMessageControl;
}
