import { Grid, useMantineColorScheme } from "@mantine/core";

import { QuickbooksButton } from "../../components/Buttons/QuickbooksButton.js";
import { SlackButton } from "../../components/Buttons/SlackButton.js";
import { ArticleCardImage } from "../../components/Cards/ArticleCardImage.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";

const cardData = [
  {
    id: 1,
    logoDark: "/images/quickbooks.svg",
    logoLight: "/images/quickbooks-light.svg",
    title: "Quickbooks Online Accounting API",
    scope:
      "Connect a Quickbooks Online account to create, retrieve, and update accounting data, including invoices, expenses, and transactions.",
    comingsoon: false,
    button: <QuickbooksButton />,
  },
  {
    id: 3,
    logoDark: "/images/slack.svg",
    logoLight: "/images/slack-light.svg",
    title: "Slack API",
    scope:
      "Connect your Slack workspace to invite Penny to join conversations, answer questions, and assist directly within your Slack channels.",
    comingsoon: false,
    button: <SlackButton />,
  },
];

export function ConnectOverview() {
  const theme = useMantineColorScheme();
  const isDarkMode = theme.colorScheme === "dark";

  const { customer } = useActiveStates();

  const filteredCardData = cardData.filter((card) => {
    // Only show the Slack card if the user is a service provider
    if (card.id === 3 && !customer) {
      return true;
    }
    return true;
  });

  return (
    <Layout>
      <PageTitle title="Welcome to LedgerAI" />

      <Grid p="md">
        {filteredCardData.map((card, index) => (
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} key={index}>
            <ArticleCardImage
              id={card.id}
              scope={card.scope}
              logo={isDarkMode ? card.logoDark : card.logoLight}
              title={card.title}
              comingsoon={card.comingsoon}
              button={card.button}
            />
          </Grid.Col>
        ))}
      </Grid>
    </Layout>
  );
}
