import { StringField } from "../types/field";

export const colorField: StringField<{
  name: "color";
  required: true;
}> = {
  label: "Color",
  name: "color",
  required: true,
  type: "string",
};
