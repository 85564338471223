import { useEffect, useState } from "react";

import { OrganizationRelationshipUserEntity } from "../../../common/entities/organizationRelationshipUser.js";
import { getUserName, UserEntity } from "../../../common/entities/user.js";
import { userClient } from "../../../common/utils/entityClient.js";
import { clientError } from "../../utils/clientError.js";
import { Group, Text } from "@mantine/core";
import { UserAvatar } from "../Avatars/UserAvatar.js";
import { OrganizationUserEntity } from "../../../common/entities/organizationUser.js";

export const USER_PROFILE_CACHE = new Map<number, UserEntity>(); // cache user profiles to avoid multiple requests
const USER_PROFILE_FETCH_CACHE = new Map<number, Promise<UserEntity>>();
const USER_PROFILE_RESOLVE_CACHE = new Map<
  number,
  (user: UserEntity) => void
>();
export function addUsersToCache(users: UserEntity[]) {
  users.forEach((user) => {
    const userId = user.id!;
    if (!USER_PROFILE_CACHE.has(userId)) {
      USER_PROFILE_CACHE.set(userId, user);
    }
    if (USER_PROFILE_RESOLVE_CACHE.has(userId)) {
      USER_PROFILE_RESOLVE_CACHE.get(userId)!(user);
    }
  });
}
export function fetchUserCached(
  id: number,
  userData: UserEntity | undefined,
  setUserData: (user: UserEntity) => void
) {
  const fetchUser = async () => {
    const cachedUser = USER_PROFILE_CACHE.get(id);
    if (userData) {
      if (!cachedUser) {
        USER_PROFILE_CACHE.set(id, userData);
      }
      return;
    }
    if (cachedUser) {
      setUserData(cachedUser);
      return;
    }
    if (USER_PROFILE_FETCH_CACHE.has(id)) {
      setUserData(await USER_PROFILE_FETCH_CACHE.get(id)!);
      return;
    }
    USER_PROFILE_FETCH_CACHE.set(
      id,
      new Promise(async (resolve, reject) => {
        USER_PROFILE_RESOLVE_CACHE.set(id, resolve);
        const userResponse = await userClient(clientError).item(id).get();
        if (
          userResponse.success &&
          "data" in userResponse.data &&
          userResponse.data.success
        ) {
          USER_PROFILE_CACHE.set(id, userResponse.data.data);
          setUserData(userResponse.data.data);
          resolve(userResponse.data.data);
        } else {
          reject(userResponse.success ? "No user data" : userResponse.error);
        }
        USER_PROFILE_RESOLVE_CACHE.delete(id);
      })
    );
  };
  fetchUser();
}

export function UserProfile({
  user,
  withAvatar = false,
  withArchived = false,
}: {
  user:
    | UserEntity
    | (OrganizationUserEntity & { User?: UserEntity }) // User is a join field
    | OrganizationRelationshipUserEntity;
  withAvatar?: boolean;
  withArchived?: boolean;
}) {
  const id_to_fetch = (() => {
    if ("user_id" in user) {
      return user.user_id!;
    } else if ("id" in user) {
      return user.id!;
    } else {
      throw new Error("User has no id or user_id");
    }
  })();
  const [userData, setUserData] = useState<UserEntity | undefined>(
    "User" in user ? user.User : undefined
  );
  useEffect(() => {
    fetchUserCached(id_to_fetch, userData, setUserData);
  }, [
    (user as OrganizationRelationshipUserEntity)?.user_id ??
      (user as UserEntity)?.id,
    setUserData,
  ]);

  if (!userData) {
    return (
      <div>
        Loading{" "}
        {"id" in user ? user.id : "user_id" in user ? user.user_id : "N/A"}...
      </div>
    );
  }

  if (withAvatar) {
    if (userData.archived_at) {
      return <Text>Removed</Text>;
    }
    return (
      <Group gap="sm">
        <UserAvatar user={userData} size={45} radius={80} />
        <div>
          <Text fw={700}> {getUserName(userData)}</Text>
          <Text fz="sm" c="dimmed">
            {user.archived_at ? "Added on" : "Member since"}{" "}
            {new Date(user.created_at ?? Date.now()).toLocaleDateString(
              undefined,
              {
                month: "short",
                day: "numeric",
                year: "numeric",
              }
            )}
          </Text>
          {withArchived && user.archived_at && (
            <Text fz="sm" c="dimmed">
              Removed on{" "}
              {new Date(user.archived_at).toLocaleDateString(undefined, {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </Text>
          )}
        </div>
      </Group>
    );
  }

  return <div>{getUserName(userData)}</div>;
}
