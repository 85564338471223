import { ActionIcon, Card, Group, Menu, Text } from "@mantine/core";
import { IconAutomaticGearbox } from "@tabler/icons-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAdmin } from "../../contexts/AdminContext.js";
import { AccountPrimaryColor } from "../../theme.js";

export function AdminMainMenu() {
  const [menuOpened, setMenuOpened] = useState(false);
  const { user } = useAdmin();
  return (
    <Menu shadow="md" opened={menuOpened} onChange={setMenuOpened}>
      <Menu.Target>
        <Group gap={0}>
          <ActionIcon variant="default" size="lg">
            <IconAutomaticGearbox size={20} />
          </ActionIcon>
        </Group>
      </Menu.Target>

      <Menu.Dropdown pt={0}>
        <Card radius="lg">
          <Card.Section bg={AccountPrimaryColor}>
            <Text ta="center" py="md" c="white" size="sm" fw={600}>
              Hello {user.email}
            </Text>
          </Card.Section>
        </Card>
        <Menu.Item component={Link} to="/ai">
          AI Providers & Bots
        </Menu.Item>
        <Menu.Item component={Link} to="/docs/0">
          API Documentation
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
