import { ActionIcon, Loader, Textarea, rem } from "@mantine/core";
import { IconArrowUp } from "@tabler/icons-react";
import { ChangeEvent, useEffect, useRef } from "react";

import classes from "../../components/Messages/MessageDisplay.css.js";

type ChatInputProps = {
  input: string;
  loading: boolean;
  setInput: (input: ChangeEvent<HTMLTextAreaElement>) => void;
  onSend: () => Promise<void> | void;
};

export function ChatInput({
  input,
  setInput,
  onSend,
  loading,
}: ChatInputProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  // Set focus to the textarea when input is cleared
  useEffect(() => {
    if (input === "" && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [input]);

  const handleSend = async () => {
    if (!input.trim() || loading) return;

    console.log("ChatInput - Sending message:", input.trim());
    await onSend();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevents newline character
      handleSend();
    }
  };

  return (
    <>
      <Textarea
        ref={textareaRef} // Attach the ref here
        rightSection={
          <ActionIcon
            size="md"
            radius="sm"
            variant="filled"
            aria-label="Send"
            onClick={handleSend}
            disabled={!input.trim() || loading}
            style={{ position: "absolute", bottom: rem(6), right: rem(6) }}
          >
            {loading ? (
              <Loader size="xs" type="dots" />
            ) : (
              <IconArrowUp style={{ width: rem(20), height: rem(20) }} />
            )}
          </ActionIcon>
        }
        variant="default"
        size="md"
        value={input}
        onChange={setInput}
        placeholder="Message Penny..."
        autosize
        radius={0}
        minRows={4}
        maxRows={4}
        className={classes.textarea}
        onKeyDown={handleKeyDown}
        disabled={loading}
      />
    </>
  );
}
