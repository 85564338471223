import {
  Box,
  Button,
  Group,
  Menu,
  MenuItem,
  MenuLabel,
  ScrollArea,
  Stack,
  Text,
  Title,
  UnstyledButton,
} from "@mantine/core";
import {
  IconChevronDown,
  IconCircleCheckFilled,
  IconPlus,
} from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrganizationEntity } from "../../../common/entities/organization.js";
import { LEDGERAI_SLUG } from "../../../common/fields/slug.js";
import { organizationClient } from "../../../common/utils/entityClient.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { PrimaryColor } from "../../theme.js";
import { clientError } from "../../utils/clientError.js";
import { OrganizationAvatar } from "../Avatars/OrganizationAvatar.js";
import { OrganizationModal } from "../Modals/OrganizationModal.js";

export const CustomerMenu = () => {
  const navigate = useNavigate();
  const {
    customer: activeCustomer,
    customers,
    serviceProvider,
    isCustomerUser,
    setCustomer,
    setCustomers,
  } = useActiveStates();

  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const [menu2Opened, setMenu2Opened] = useState<boolean>(false);

  // Use active customer if available, otherwise use service provider
  const activeOrganization = activeCustomer?.id
    ? activeCustomer
    : serviceProvider;
  const isLedgerAI = serviceProvider?.slug === LEDGERAI_SLUG;

  const handleSubmit = async (newOrg: Partial<OrganizationEntity>) => {
    const response = await organizationClient(clientError).create(newOrg);
    if (response.success && response.data.success) {
      const createdOrg = response.data.data;
      setCustomers((prev) => [...(prev ?? []), createdOrg]);
      setCustomer(createdOrg);
    } else if (response.success) {
      throw new Error(
        response.data.success === false
          ? response.data.errors.join(";")
          : `HTTP ${response.status.toString(10)}`
      );
    } else {
      try {
        const errResponse = JSON.parse(response.text);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errError: any = new Error(
          errResponse.errors?.join("; ") ?? response.text
        );
        errError.errors = errResponse.errors;
        throw errError;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if ("errors" in e) {
          throw e;
        }
        throw new Error(
          `${e?.message ?? e}; ${
            response.error ?? `HTTP ${response.status.toString(10)}}`
          }`
        );
      }
    }
  };

  // Simplified text helper - only handles the variable parts
  const menuText = isLedgerAI
    ? {
        label: "LedgerAI Service Providers",
        button: "a service provider",
        empty: "No service providers yet",
      }
    : {
        label: `${serviceProvider?.name} Clients`,
        button: "a client",
        empty: "No clients yet",
      };

  if (isCustomerUser) {
    return (
      <Group gap={5}>
        <OrganizationAvatar
          organization={activeOrganization}
          variant="filled"
          mr="lg"
          size={45}
          radius={activeOrganization?.picture ? 0 : "sm"}
        />
        <Title order={4}>
          {activeOrganization?.name ?? "No organization selected"}
        </Title>
      </Group>
    );
  }

  if (!Array.isArray(customers)) {
    throw new Error("Customers must be an array");
  }

  return (
    <>
      <Group gap={20}>
        <Menu
          withArrow
          shadow="md"
          opened={menuOpened}
          onChange={setMenuOpened}
        >
          <Menu.Target>
            <UnstyledButton onClick={() => setMenuOpened((o) => !o)}>
              <OrganizationAvatar
                organization={activeOrganization}
                size={45}
                radius={activeOrganization?.picture ? 0 : "sm"}
                color={PrimaryColor}
                bg={PrimaryColor}
                src={activeOrganization?.picture}
                hasShadow={customers.length > 1}
              />
            </UnstyledButton>
          </Menu.Target>

          <Menu.Dropdown>
            <MenuItem
              key={serviceProvider?.id}
              onClick={() => setCustomer(undefined)}
              leftSection={
                <OrganizationAvatar
                  organization={serviceProvider}
                  variant="filled"
                  size={45}
                  color={serviceProvider?.theme_primary_color ?? "#228BE6"}
                  radius={serviceProvider?.picture ? 0 : "sm"}
                />
              }
              rightSection={
                activeOrganization?.id === serviceProvider?.id ? (
                  <IconCircleCheckFilled color="#00ba7c" size={19} />
                ) : null
              }
            >
              <Stack mr="xl" gap={0}>
                <Text fw={700}> {serviceProvider?.name}</Text>
                <Text c="dimmed" size="sm" fw={500}>
                  @{serviceProvider?.slug}
                </Text>
              </Stack>
            </MenuItem>
            <Menu.Divider />
            <MenuLabel>{menuText.label}</MenuLabel>

            <ScrollArea.Autosize mah={280} type="scroll">
              {customers.length > 0 ? (
                customers.map((org) => (
                  <MenuItem
                    key={org.id}
                    onClick={() => setCustomer(org)}
                    leftSection={
                      <OrganizationAvatar
                        organization={org}
                        variant="filled"
                        size={45}
                        color={org.theme_primary_color ?? "#228BE6"}
                        radius={org?.picture ? 0 : "sm"}
                      />
                    }
                    rightSection={
                      org.id === activeCustomer?.id ? (
                        <IconCircleCheckFilled color="#00ba7c" size={19} />
                      ) : null
                    }
                  >
                    <Stack mr="xl" gap={0}>
                      <Text fw={700}> {org.name}</Text>
                      <Text color="dimmed" size="sm" fw={500}>
                        @{org.slug}
                      </Text>
                    </Stack>
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>{menuText.empty}</MenuItem>
              )}
            </ScrollArea.Autosize>

            <Menu.Divider />
            <Box m="lg">
              <Button
                size="sm"
                variant="default"
                fullWidth
                onClick={() => setCreateModalOpened(true)}
                leftSection={<IconPlus size={16} stroke={2} />}
              >
                Add {menuText.button}
              </Button>
            </Box>
          </Menu.Dropdown>
        </Menu>
        <Menu
          withArrow
          shadow="md"
          opened={menu2Opened}
          onChange={setMenu2Opened}
        >
          <Menu.Target>
            <UnstyledButton onClick={() => setMenu2Opened((o) => !o)}>
              <Group gap={0}>
                <Title order={4} mr={10}>
                  {activeCustomer?.name || activeOrganization?.name}
                </Title>
                <IconChevronDown stroke={2} size={16} />
              </Group>
            </UnstyledButton>
          </Menu.Target>

          <Menu.Dropdown>
            <MenuItem
              key={activeOrganization.id}
              onClick={() => setCustomer(activeOrganization)}
              leftSection={
                <OrganizationAvatar
                  organization={activeOrganization}
                  variant="filled"
                  size={45}
                  color={activeOrganization.theme_primary_color ?? "#228BE6"}
                  radius="sm"
                />
              }
            >
              <Stack mr="xl" gap={0}>
                <Text fw={700}> {activeOrganization.name}</Text>
                <Text color="dimmed" size="sm" fw={500}>
                  @{activeOrganization.slug}
                </Text>
              </Stack>
            </MenuItem>
            <Menu.Divider />
            <Menu.Item>Invite people to {activeOrganization.name}</Menu.Item>
            <Menu.Divider />
            <MenuLabel>Settings</MenuLabel>
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation(); // Prevents the menu from toggling
                navigate("/settings/overview");
              }}
            >
              Customize Workspace
            </Menu.Item>
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation(); // Prevents the menu from toggling
                navigate("/settings/overview");
              }}
            >
              Notifications
            </Menu.Item>
            <MenuLabel>Admin</MenuLabel>
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation(); // Prevents the menu from toggling
                navigate("/connect/overview");
              }}
            >
              Manage Apps
            </Menu.Item>
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation(); // Prevents the menu from toggling
                navigate("/people/overview");
              }}
            >
              Manage Members
            </Menu.Item>
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation(); // Prevents the menu from toggling
                navigate("/clients/overview");
              }}
            >
              Manage {isLedgerAI ? "Service Providers" : "Clients"}
            </Menu.Item>
            {/* <Menu.Item>Billing</Menu.Item> */}
          </Menu.Dropdown>
        </Menu>
      </Group>

      <OrganizationModal
        editMode={false}
        onClose={() => setCreateModalOpened(false)}
        onSubmit={handleSubmit}
        opened={createModalOpened}
      />
    </>
  );
};
