import { notifications } from "@mantine/notifications";

export const clientError = (error: string) => {
  notifications.show({
    autoClose: 30e3,
    color: "red",
    message: error,
    position: "bottom-center",
    title: "Error",
  });
};
