import { createContext } from "react";

type AsideContextType = {
  opened: boolean;
  openPennyChat: () => void;
  closeAside: () => void;
};

export const AsideContext = createContext<AsideContextType | undefined>(
  undefined
);
