import { Box, Group, Loader, Table, Text } from "@mantine/core";
import { useEffect, useState } from "react";

import { IconBoxModel } from "@tabler/icons-react";
import { AIModelEntity } from "../../../common/entities/aiModel.js";
import { AIProviderEntity } from "../../../common/entities/aiProvider.js";
import {
  aiModelClient,
  aiProviderClient,
} from "../../../common/utils/entityClient.js";
import { PrimaryColor } from "../../theme.js";
import { clientError } from "../../utils/clientError.js";

export function AdminAIProvidersList() {
  const [aiModels, setAIModels] = useState<AIModelEntity[]>([]);
  const [aiProviders, setAIProviders] = useState<AIProviderEntity[]>([]);

  useEffect(function () {
    async function load() {
      const listModelsResponse = await aiModelClient(clientError).list();
      if (listModelsResponse.success) {
        if (listModelsResponse.data.success) {
          setAIModels(listModelsResponse.data.data);
        }
      }
      const listProvidersResponse = await aiProviderClient(clientError).list();
      if (listProvidersResponse.success) {
        if (listProvidersResponse.data.success) {
          setAIProviders(listProvidersResponse.data.data);
        }
      }
    }
    load().catch((e) => console.error(e));
  }, []);

  if (aiProviders.length === 0 || aiModels.length === 0) {
    return (
      <Box p="xs" ta="center">
        <Loader />
      </Box>
    );
  }

  return (
    <Table>
      <Table.Tbody>
        <Table.Tr>
          <Table.Th>
            <Text p="xs" fw={600} w={240}>
              Provider Name
            </Text>
          </Table.Th>
          <Table.Th>
            <Text p="xs" fw={600}>
              Available Models
            </Text>
          </Table.Th>
        </Table.Tr>
        {aiProviders.map((p) => AdminAIProviderListItem(p, aiModels))}
      </Table.Tbody>
    </Table>
  );
}

export function AdminAIProviderListItem(
  aiProvider: AIProviderEntity,
  models: AIModelEntity[]
) {
  return (
    <Table.Tr key={aiProvider.id}>
      <Table.Td p="xs">
        <Text size="lg">{aiProvider.name}</Text>
      </Table.Td>
      <Table.Td p="xs">
        {models
          .filter((m) => m.ai_provider_id === aiProvider.id)
          .map((m) => (
            <Group
              c={PrimaryColor}
              key={m.id}
              gap={0}
              style={{ whiteSpace: "nowrap", flexWrap: "nowrap" }}
            >
              <IconBoxModel />
              <Text
                size="sm"
                title={`${m.name} (${m.slug})`}
                style={{
                  maxWidth: "80%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {m.name}
              </Text>
            </Group>
          ))}
      </Table.Td>
    </Table.Tr>
  );
}
