import { archivedAtField } from "../fields/archived_at";
import { createdAtField } from "../fields/created_at";
import {
  customerIdField,
  customerIdFieldRequired,
} from "../fields/customer_id";
import { entitlementsField } from "../fields/entitlements";
import {
  providerIdField,
  providerIdFieldRequired,
} from "../fields/provider_id";
import { userIdFieldRequired } from "../fields/user_id";
import { EntityDocs } from "../types/docs";
import { entity, EntityType } from "../types/entity";

// Docs
export const docs: EntityDocs = {
  collection: {
    summary:
      "List organization relationship users, which are the users which are permitted to access the specified customer (or client) as employees of the the provider (or service provider). Not all service provider employees have visibility into all clients.",
  },
  item: { summary: "Perform operations on an organization relationship user." },
};

export const fields = [
  archivedAtField,
  createdAtField,
  customerIdFieldRequired,
  entitlementsField,
  providerIdFieldRequired,
  userIdFieldRequired,
] as const;

export const primaryKeyFields = [
  providerIdField,
  customerIdField,
  userIdFieldRequired,
] as const;

export const organizationRelationshipUserEntity = entity({
  api: {
    collectionPath: "organization-relationship-users",
    itemPath:
      "organization-relationship-users/[provider_id]/[customer_id]/[user_id]",
  },
  docs,
  name: "OrganizationRelationshipUser",
  fields,
  primaryKeyFields,
  tableName: "organization_relationship_users",
});

export type OrganizationRelationshipUserEntity = EntityType<
  typeof fields,
  typeof primaryKeyFields
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleOrganizationRelationshipUser: OrganizationRelationshipUserEntity =
  {
    archived_at: new Date().toISOString(),
    created_at: new Date().toISOString(),
    customer_id: 765,
    entitlements: [],
    provider_id: 543,
    user_id: 123,
  };
