import {
  Button,
  ColorInput,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { IconAt } from "@tabler/icons-react";
import { useMemo } from "react";

import {
  organizationEntity,
  OrganizationUpdateType,
} from "../../../common/entities/organization.js";
import { initialsField } from "../../../common/fields/initials.js";
import { nameField } from "../../../common/fields/name.js";
import { pictureField } from "../../../common/fields/picture.js";
import { pictureBackgroundColorField } from "../../../common/fields/picture_background_color.js";
import { primaryDomainField } from "../../../common/fields/primary_domain.js";
import { primaryLocationField } from "../../../common/fields/primary_location.js";
import { LEDGERAI_SLUG, slugField } from "../../../common/fields/slug.js";
import { themePrimaryColorField } from "../../../common/fields/theme_primary_color.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { useForm } from "../../hooks/useForm.js";
import { ServiceProviderPrimaryColor } from "../../theme.js";
import { OrganizationAvatar } from "../Avatars/OrganizationAvatar.js";

interface OrganizationModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: OrganizationUpdateType) => Promise<void>;
  editMode?: boolean; // true if editing, false if adding
  initialData?: Partial<OrganizationUpdateType>; // Prefill data for editing
}

export function OrganizationModal({
  opened,
  onClose,
  onSubmit,
  editMode = false,
  initialData,
}: OrganizationModalProps) {
  const { serviceProvider } = useActiveStates();
  const initialFormData: OrganizationUpdateType = useMemo(
    () => ({
      entitlements: [] as unknown as number | undefined,
      extend_entitlements: [] as unknown as number | undefined,
      initials: "",
      name: "",
      picture: "",
      primary_domain: "",
      primary_location: "",
      slug: "",
      theme_primary_color: "#228BE6",
      ...initialData,
    }),
    [initialData]
  );

  const form = useForm(organizationEntity, {
    initialFormData,
    onClose,
    onSubmit,
    opened,
    type: editMode ? "update" : "create",
    errorComponent(message: string) {
      return <Text c="red">{message}</Text>;
    },
  });

  const providerIsLedgerAI = serviceProvider?.slug === LEDGERAI_SLUG;

  return (
    <Modal opened={opened} onClose={onClose} centered>
      {serviceProvider && (
        <Stack gap={0} pb="xl">
          <OrganizationAvatar
            variant="filled"
            color={ServiceProviderPrimaryColor}
            size={60}
            radius="sm"
            mx="auto"
            organization={serviceProvider}
            bg={ServiceProviderPrimaryColor}
          />
          <Text ta="center" fz="lg" fw={500} mt="md">
            {editMode
              ? `Edit ${providerIsLedgerAI ? "Service Provider" : "Client"}`
              : `Add a New ${
                  providerIsLedgerAI ? "Service Provider" : "Client"
                }`}
          </Text>
        </Stack>
      )}
      <form onSubmit={form.submit}>
        {form.fieldError(nameField)}
        <TextInput
          disabled={form.isSubmitting}
          label={`${providerIsLedgerAI ? "Service Provider" : "Client"} Name`}
          placeholder="e.g., Jack's Kitchen"
          required={nameField.required}
          value={form.data.name}
          onChange={(e) =>
            form.handleInputChange("name", e.currentTarget.value)
          }
          mb="sm"
        />
        {form.fieldError(initialsField)}
        <TextInput
          disabled={form.isSubmitting}
          leftSection={<IconAt size={16} />}
          label={initialsField.label}
          placeholder="e.g., JK"
          required={initialsField.required}
          value={form.data.initials}
          onChange={(e) =>
            form.handleInputChange("initials", e.currentTarget.value)
          }
          mb="sm"
        />
        {form.fieldError(primaryDomainField)}
        <TextInput
          disabled={form.isSubmitting}
          label={primaryDomainField.label}
          placeholder="e.g., jackskitchen.com"
          required={primaryDomainField.required}
          value={form.data.primary_domain}
          onChange={(e) =>
            form.handleInputChange("primary_domain", e.currentTarget.value)
          }
          mb="sm"
        />
        {form.fieldError(slugField)}
        <TextInput
          disabled={form.isSubmitting}
          leftSection={<IconAt size={16} />}
          label={slugField.label}
          placeholder="e.g., jackskitchen"
          required={slugField.required}
          value={form.data.slug}
          onChange={(e) =>
            form.handleInputChange("slug", e.currentTarget.value)
          }
          mb="sm"
        />
        {form.fieldError(themePrimaryColorField)}
        <ColorInput
          disabled={form.isSubmitting}
          label={themePrimaryColorField.label}
          placeholder="Pick a color"
          required={themePrimaryColorField.required}
          value={form.data.theme_primary_color ?? "#228BE6"}
          onChange={(value) =>
            form.handleInputChange(
              themePrimaryColorField.name,
              value || "#000000"
            )
          }
          mb="sm"
        />
        {form.fieldError(primaryLocationField)}
        <TextInput
          disabled={form.isSubmitting}
          label={primaryLocationField.label}
          placeholder="e.g., Salt Lake City, UT"
          required={primaryLocationField.required}
          value={form.data.primary_location}
          onChange={(e) =>
            form.handleInputChange("primary_location", e.currentTarget.value)
          }
          mb="sm"
        />
        {form.fieldError(pictureField)}
        <TextInput
          disabled={form.isSubmitting}
          label={pictureField.label}
          placeholder="e.g., https://example.org/image"
          required={pictureField.required}
          value={form.data.picture}
          onChange={(e) =>
            form.handleInputChange("picture", e.currentTarget.value)
          }
          mb="sm"
        />
        {form.data.picture && (
          <>
            {" "}
            {form.fieldError(pictureBackgroundColorField)}
            <ColorInput
              disabled={form.isSubmitting}
              label={pictureBackgroundColorField.label}
              placeholder="Pick a color"
              required={pictureBackgroundColorField.required}
              value={form.data.picture_background_color ?? ""}
              onChange={(value) =>
                form.handleInputChange(
                  pictureBackgroundColorField.name,
                  value || ""
                )
              }
              mb="sm"
            />{" "}
            <Stack>
              <Title order={5} ta="center">
                Avatar Preview
              </Title>
              <OrganizationAvatar
                organization={form.data}
                variant="filled"
                size={60}
                radius="sm"
                mx="auto"
              />
            </Stack>
          </>
        )}
        {form.overallError()}
        <Group mt="md">
          <Button
            bg={ServiceProviderPrimaryColor}
            disabled={form.isSubmitting}
            fullWidth
            type="submit"
          >
            {editMode
              ? `Save ${providerIsLedgerAI ? "Service Provider" : "Client"}`
              : `Add ${providerIsLedgerAI ? "Service Provider" : "Client"}`}
          </Button>
        </Group>
      </form>
    </Modal>
  );
}
