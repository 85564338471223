import { customerIdField } from "../fields/customer_id";
import { providerIdField } from "../fields/provider_id";
import { timestampField } from "../fields/timestamp";
import { userIdFieldRequired } from "../fields/user_id";
import { EntityDocs } from "../types/docs";
import {
  entity,
  EntityType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";

export const fields = [
  userIdFieldRequired,
  providerIdField,
  customerIdField,
  timestampField,
] satisfies FieldList;

export const primaryKeyFields = [
  userIdFieldRequired,
  providerIdField,
  customerIdField,
] satisfies PrimaryKeyFieldTuple;

// Docs
export const docs: EntityDocs = {
  collection: {
    summary:
      "List message last read markers. This controls the activity red dot on the left sidebar menu activity icon.",
  },
  item: { summary: "Perform operations on a message last read marker." },
};

export const messagesLastReadEntity = entity({
  api: {
    collectionPath: "messages-last-read",
    itemPath: "messages-last-read/mark-read",
    list: {
      missingFilterImpliesNull: true,
    },
  },
  docs,
  name: "MessagesLastRead",
  fields,
  primaryKeyFields,
  tableName: "messages_last_read",
});

export type MessagesLastReadEntity = EntityType<
  typeof messagesLastReadEntity.fields,
  typeof messagesLastReadEntity.primaryKeyFields
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleMessagesLastRead: MessagesLastReadEntity = {
  provider_id: 765,
  timestamp: new Date().toISOString(),
  user_id: 543,
};
