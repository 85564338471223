import { Box, Grid, Loader } from "@mantine/core";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { toConversation } from "../../../common/core/messages.js";
import { UserEntity } from "../../../common/entities/user.js";
import {
  conversationClient,
  userClient,
} from "../../../common/utils/entityClient.js";
import { addUsersToCache } from "../../components/Excelente/UserProfile.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { clientError } from "../../utils/clientError.js";
import {
  PennyConversation,
  PennyConversationState,
} from "./PennyConversation.js";
import { PennyConversationList } from "./PennyConversationList.js";

import classes from "./PennyConversationList.css.js";

export function PennyConversationRoute() {
  const { id } = useParams();

  const lastId = useRef(id);

  const [state, setState] = useState<PennyConversationState>({
    conversation: undefined,
  });

  useEffect(() => {
    if (lastId.current !== id) {
      setState({
        conversation: undefined,
      });
    }
    lastId.current = id;
  }, [id]);

  useEffect(() => {
    async function load() {
      if (id === "new") {
        setState({
          conversation: {
            id: "new" as unknown as number,
            threads: [],
            created_at: new Date(),
            updated_at: new Date(),
            title: "New Conversation",
          },
        });
        return;
      }
      const integerId = typeof id === "string" ? parseInt(id) : NaN;
      if (isNaN(integerId)) {
        return;
      }
      const response = await conversationClient(clientError)
        .item(integerId)
        .get();
      if (response.success && "data" in response && "data" in response.data) {
        const conversation = await toConversation(
          clientError,
          response.data.data
        );
        if (id !== lastId.current) {
          return;
        }
        setState({
          conversation,
        });
      }
    }
    load();
  }, [id]);

  const setConversationState = useCallback(
    (state: PennyConversationState) => {
      if (
        id === "new" &&
        state.conversation &&
        (state.conversation.id as unknown as string) !== "new"
      ) {
        window.history.replaceState(
          null,
          "",
          `/penny/conversations/${state.conversation?.id}`
        );
      }
      setState(state);
    },
    [location, setState]
  );

  const { user, serviceProvider, customer } = useActiveStates();
  const organization = customer || serviceProvider;

  const [users, setUsers] = useState<UserEntity[]>([]);

  const fetchUsers = useCallback(async () => {
    const response = await userClient(clientError).list({
      organization_id: organization?.id,
    });
    if (response.success && "data" in response && "data" in response.data) {
      setUsers(response.data.data);
      addUsersToCache(response.data.data);
    }
  }, [organization?.id]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  const conversationElement = useMemo(() => {
    if (state.conversation) {
      return (
        <PennyConversation
          key={id}
          state={state}
          setState={setConversationState}
          user={user}
          users={users}
          organization={organization}
          customer={customer}
          serviceProvider={serviceProvider}
        />
      );
    }
    return (
      <Box
        style={{
          alignContent: "flex-end",
          alignItems: "center",
          display: "flex",
          height: "fit-content",
          justifyContent: "center",
          overflowY: "auto",
          padding: "100px",
        }}
      >
        <Loader />
      </Box>
    );
  }, [state, setConversationState, id]);

  return (
    <Layout>
      <PageTitle title={state.conversation?.title ?? "New Chat"} />
      <Grid style={{ "--grid-col-padding": "0", "--grid-gutter": "0" }}>
        <Grid.Col
          span={{ base: 12, md: 3, lg: 3, xl: 2 }}
          className={classes.container}
        >
          <PennyConversationList />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 9, lg: 9, xl: 10 }}>
          {conversationElement}
        </Grid.Col>
      </Grid>
    </Layout>
  );
}
