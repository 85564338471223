import {
  Box,
  Button,
  Divider,
  Group,
  Loader,
  Stack,
  Title,
} from "@mantine/core";
import { useCallback, useEffect, useState } from "react";

import {
  organizationEntity,
  OrganizationEntity,
} from "../../../common/entities/organization.js";
import { customerIdField } from "../../../common/fields/customer_id.js";
import { idField } from "../../../common/fields/id.js";
import { providerIdField } from "../../../common/fields/provider_id.js";
import { LEDGERAI_SLUG } from "../../../common/fields/slug.js";
import {
  organizationClient,
  organizationRelationshipClient,
} from "../../../common/utils/entityClient.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { clientError } from "../../utils/clientError.js";
import { OrganizationModal } from "../Modals/OrganizationModal.js";
import {
  OrganizationsTable,
  OrgRelationship,
} from "../Tables/OrganizationsTable.js";
import { notifications } from "@mantine/notifications";

export function OrganizationManagement() {
  const [isLoading, setIsLoading] = useState(true);
  const [isDeletingCustomerId, setIsDeletingCustomerId] = useState<
    number | null
  >(null);
  const { setCustomers, setCustomer, serviceProvider } = useActiveStates();
  const [organizationRelationships, setOrganizationRelationships] = useState<
    OrgRelationship[]
  >([]);
  const [createModalOpened, setCreateModalOpened] = useState(false);

  const providerIsLedgerAI = serviceProvider?.slug === LEDGERAI_SLUG;

  const fetchOrganizationRelationships = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await organizationRelationshipClient(clientError).list(
        {
          [providerIdField.name]: serviceProvider?.id,
        },
        {
          join: organizationEntity.name,
          from: idField.name,
          to: customerIdField.name,
        }
      );
      if (response.success && "data" in response && "data" in response.data) {
        setOrganizationRelationships(response.data.data as OrgRelationship[]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [serviceProvider?.id]);

  useEffect(() => {
    fetchOrganizationRelationships();
  }, [fetchOrganizationRelationships]);

  const handleCreate = async (newOrg: Partial<OrganizationEntity>) => {
    const response = await organizationClient(clientError).create(newOrg);
    if (response.success && "data" in response && "data" in response.data) {
      await fetchOrganizationRelationships();

      const newCustomer = response.data.data;
      setCustomers((prev: OrganizationEntity[] | undefined) => [
        ...(prev ?? []),
        newCustomer,
      ]);
      setCustomer(newCustomer);

      setCreateModalOpened(false);
    }
  };

  const handleEdit = async (updatedOrg: OrganizationEntity) => {
    if (!updatedOrg.id) return;

    const response = await organizationClient(clientError)
      .item(updatedOrg.id)
      .update(updatedOrg);
    if (response.success && "data" in response && "data" in response.data) {
      await fetchOrganizationRelationships();
    }
  };

  const handleDelete = async (relationship: OrgRelationship) => {
    if (!relationship.provider_id || !relationship.customer_id) {
      console.error(
        "Provider ID or Customer ID is missing when trying to delete organization relationship"
      );
      return;
    }
    setIsDeletingCustomerId(relationship.customer_id);
    const response = await organizationRelationshipClient(clientError)
      .item(relationship.provider_id!, relationship.customer_id!)
      .delete();
    if (response.success) {
      await fetchOrganizationRelationships();
      setIsDeletingCustomerId(null);
      notifications.show({
        title: "Success",
        message: `Organization relationship with ${relationship.Organization.name} has been removed.`,
        color: "green",
      });
    } else {
      notifications.show({
        title: "Error",
        message: `Failed to remove organization relationship with ${relationship.Organization.name}.`,
        color: "red",
      });
      setIsDeletingCustomerId(null);
    }
  };

  const title = providerIsLedgerAI ? "Service Providers" : "Clients";

  return (
    <Box w="100%">
      <Stack gap={0}>
        <Box py="md" px="lg">
          <Group justify="space-between" align="center">
            <Title order={4}>{title}</Title>
            <Button onClick={() => setCreateModalOpened(true)}>
              Add {providerIsLedgerAI ? "Service Provider" : "Client"}
            </Button>
          </Group>
        </Box>
        <Divider />

        <OrganizationsTable
          organizations={organizationRelationships}
          onEdit={handleEdit}
          onDelete={handleDelete}
          isDeletingCustomerId={isDeletingCustomerId}
        />

        {organizationRelationships.length === 0 && !isLoading && (
          <Box w="100%">
            <Title order={4}>
              No {providerIsLedgerAI ? "Service Providers" : "Clients"} found.
            </Title>
          </Box>
        )}

        {isLoading && organizationRelationships.length === 0 && (
          <Box
            style={{
              alignContent: "flex-end",
              alignItems: "center",
              display: "flex",
              height: "fit-content",
              justifyContent: "center",
              overflowY: "auto",
              padding: "100px",
            }}
          >
            <Loader />
          </Box>
        )}

        <OrganizationModal
          opened={createModalOpened}
          onClose={() => setCreateModalOpened(false)}
          onSubmit={handleCreate}
          editMode={false}
        />
      </Stack>
    </Box>
  );
}
