import { notifications } from "@mantine/notifications";
import { network } from "../../common/utils/network.js";
import { clientError } from "../utils/clientError.js";

export interface Session {
  accessToken: string;
  data: {
    user: {
      id: string;
    };
    organization: {
      id: string;
    };
    is_customer: boolean;
    is_provider: boolean;
  };
}

export interface Authentication {
  logOut(goToUrl: (url: string) => void): Promise<void>;
  logOutAll(goToUrl: (url: string) => void): Promise<void>;
  readURLHash(goToUrl: (url: string) => void): void;
  sessionId?: number;
  session?: Session;
}

let authScope: Authentication;

function readSessionWithId(sessionId: number): Session | undefined {
  const data = localStorage.getItem(`session.${sessionId}`);
  return data ? JSON.parse(data) : undefined;
}

function setSessionWithId(id: number, session?: Session) {
  if (session) {
    localStorage.setItem(`session.${id}`, JSON.stringify(session));
  } else {
    localStorage.removeItem(`session.${id}`);
  }
}

export function deleteSessionList(): void {
  Array(10)
    .fill(undefined)
    .forEach((_, id) => deleteSessionWithId(id));
}

function readSessionList(): Array<Session | undefined> {
  return Array(10)
    .fill(undefined)
    .map((_, id) => readSessionWithId(id));
}

function getNextEmptySessionId() {
  const sessionList = readSessionList();
  const nextId = sessionList.findIndex((x) => !x);
  if (nextId === -1) {
    throw new Error(
      "All 10 session slots occupied, log out of an existing session before adding another one"
    );
  }
  return nextId;
}

function deleteSessionWithId(id: number) {
  setSessionWithId(id);
}

function redirectToAnySession(goToUrl: (url: string) => void) {
  const sessionList = readSessionList();
  const existingId = sessionList.findIndex((x) => x);
  if (existingId !== -1) {
    const session = readSessionWithId(existingId);
    if (session) {
      if (sessionStorage.getItem("sessionId") !== existingId.toString(10)) {
        sessionStorage.setItem("sessionId", existingId.toString(10));
        goToUrl("/");
        return;
      }
    }
  }
  goToUrl("/api/auth/login");
}

function getSessionIdWithAccessToken(accessToken: string) {
  const sessionList = readSessionList();
  const sessionId = sessionList.findIndex(
    (x) => x?.accessToken === accessToken
  );
  return sessionId === -1 ? undefined : sessionId;
}

function getSessionIdForUserAndOrganization(
  userId: string,
  organizationId: string
) {
  const sessionList = readSessionList();
  const sessionId = sessionList.findIndex(
    (x) =>
      x?.data?.user?.id === userId &&
      x?.data?.organization?.id === organizationId
  );
  return sessionId === -1 ? undefined : sessionId;
}

function loadSessionIntoAuthScope() {
  const sessionId = parseInt(sessionStorage.getItem("sessionId") ?? "0", 10);
  const session = readSessionWithId(sessionId);
  authScope = {
    async logOut(goToUrl: (url: string) => void) {
      if (session) {
        const { accessToken } = session;
        await network.get(
          "/api/auth/logout",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
          clientError
        );
        deleteSessionWithId(sessionId);
        notifications.show({
          autoClose: 500000,
          color: "green",
          message: "You have been signed out, please sign in again",
          position: "bottom-center",
          title: "Session Expired",
        });
      }
      redirectToAnySession(goToUrl);
    },
    async logOutAll(goToUrl: (url: string) => void) {
      await Promise.all(
        readSessionList().map(async (session) => {
          try {
            if (session) {
              await network.get(
                "/api/auth/logout",
                {
                  headers: { Authorization: `Bearer ${session.accessToken}` },
                },
                clientError
              );
            }
          } catch (error) {
            console.error(error);
          }
        })
      );
      deleteSessionList();
      goToUrl("/api/auth/login");
    },
    readURLHash(goToUrl: (url: string) => void) {
      const newSessionId = getNextEmptySessionId();
      /**
       * `/#accessToken=${newSession.id}:${accessToken}&organizationId=${organization.id}&userId=${user.id}` +
       * `&email=${profile.email}&isCustomer=${is_customer}&isProvider=${is_provider}`
       */
      const hashParams = new URLSearchParams(location.hash.split("#")[1]);
      const pageUrl = location.pathname;
      const accessToken = hashParams.get("accessToken");
      const organizationId = hashParams.get("organizationId");
      const userId = hashParams.get("userId");
      const email = hashParams.get("email");
      const isCustomer = hashParams.get("isCustomer");
      const isProvider = hashParams.get("isProvider");
      if (accessToken !== null && accessToken.length > 0) {
        console.log({
          pageUrl,
          accessToken,
          organizationId,
          userId,
          email,
          isCustomer,
          isProvider,
        });
        if (userId === null) {
          throw new Error("User ID is required");
        }
        if (organizationId === null) {
          throw new Error("Organization ID is required");
        }
        const existingSessionId =
          (userId !== null && organizationId !== null
            ? getSessionIdForUserAndOrganization(userId, organizationId)
            : undefined) ?? getSessionIdWithAccessToken(accessToken);
        if (typeof existingSessionId === "number") {
          setSessionWithId(existingSessionId, {
            accessToken,
            data: {
              user: { id: userId },
              organization: { id: organizationId },
              is_customer: isCustomer === "true",
              is_provider: isProvider === "true",
            },
          });
          sessionStorage.setItem("sessionId", existingSessionId.toString(10));
        } else {
          setSessionWithId(newSessionId, {
            accessToken,
            data: {
              user: { id: userId },
              organization: { id: organizationId },
              is_customer: isCustomer === "true",
              is_provider: isProvider === "true",
            },
          });
          sessionStorage.setItem("sessionId", newSessionId.toString(10));
        }
        goToUrl(pageUrl + "?" + Math.random());
      } else {
        if (!session) {
          notifications.show({
            autoClose: 500000,
            color: "green",
            message: "Please wait, switching active session",
            position: "bottom-center",
            title: "Session Expired",
          });
          redirectToAnySession(goToUrl);
        }
      }
    },
    sessionId,
    session,
  };
}

export function getAuth(): Authentication {
  if (!authScope) {
    loadSessionIntoAuthScope();
  }
  return authScope;
}

export function useAuth(): Authentication {
  return getAuth();
}
