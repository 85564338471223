import { archivedAtField } from "../fields/archived_at";
import { createdAtField } from "../fields/created_at";
import { descriptionField } from "../fields/description";
import { entitlementsField } from "../fields/entitlements";
import { extendEntitlementsField } from "../fields/extend_entitlements";
import { idField } from "../fields/id";
import { initialsField } from "../fields/initials";
import { nameField } from "../fields/name";
import { pictureField } from "../fields/picture";
import { pictureBackgroundColorField } from "../fields/picture_background_color";
import { primaryDomainField } from "../fields/primary_domain";
import { primaryLocationField } from "../fields/primary_location";
import { slugField } from "../fields/slug";
import { themePrimaryColorField } from "../fields/theme_primary_color";
import { updatedAtField } from "../fields/updated_at";
import { EntityDocs } from "../types/docs";
import {
  entity,
  EntityCreateType,
  EntityType,
  EntityUpdateType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";

export const fields = [
  archivedAtField,
  createdAtField,
  descriptionField,
  entitlementsField,
  extendEntitlementsField,
  idField,
  initialsField,
  nameField,
  pictureBackgroundColorField,
  pictureField,
  primaryDomainField,
  primaryLocationField,
  slugField,
  themePrimaryColorField,
  updatedAtField,
] satisfies FieldList;

export const primaryKeyFields = [idField] satisfies PrimaryKeyFieldTuple;

// Docs
export const docs: EntityDocs = {
  collection: {
    summary: "List organizations.",
  },
  item: { summary: "Perform operations on an organization." },
};

export const organizationEntity = entity({
  api: {
    collectionPath: "organizations",
    itemPath: "organizations/[id]",
  },
  docs,
  name: "Organization",
  fields,
  primaryKeyFields,
  tableName: "organizations",
} as const);

export type OrganizationEntity = EntityType<
  typeof organizationEntity.fields,
  typeof organizationEntity.primaryKeyFields,
  typeof organizationEntity.name
>;

export type OrganizationCreateType = EntityCreateType<
  typeof organizationEntity.fields,
  typeof organizationEntity.primaryKeyFields,
  typeof organizationEntity.name
>;

export type OrganizationUpdateType = EntityUpdateType<
  typeof organizationEntity.fields,
  typeof organizationEntity.primaryKeyFields,
  typeof organizationEntity.name
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleOrganization: OrganizationEntity = {
  archived_at: new Date().toISOString(),
  created_at: new Date().toISOString(),
  description: "Example organization",
  entitlements: [],
  extend_entitlements: [],
  id: 1,
  initials: "EO",
  name: "Example",
  picture: "https://example.org/image",
  primary_domain: "example.org",
  primary_location: "Example City, Example",
  slug: "example",
  theme_primary_color: "#e00",
  updated_at: new Date().toISOString(),
};
