import { Button, Group } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";

import { network } from "../../../common/utils/network.js";
import { useUser } from "../../contexts/ActiveStatesContext.js";
import { clientError } from "../../utils/clientError.js";

export function SlackButton() {
  const user = useUser();
  const [connected, setConnected] = useState(false);
  const [teamName, setTeamName] = useState("");

  useEffect(() => {
    const checkSlackConnection = async () => {
      if (Math.random() > 0) {
        return;
      }
      try {
        const response = await network.get<{
          connected: boolean;
          slackData: { team_id: string };
        }>(
          `/api/slack/status`,
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
            params: { auth0_user_id: user?.auth0_user_id },
          },
          clientError
        );
        if ("data" in response) {
          setConnected(response.data.connected);
          if (response.data.connected) {
            const team_id = response.data.slackData.team_id;
            const teamResponse = await network.get<{ team_name: string }>(
              `/api/slack/team-name`,
              {
                headers: {
                  "ngrok-skip-browser-warning": "true",
                },
                params: { team_id },
              },
              clientError
            );
            {
              if ("data" in teamResponse && teamResponse.data) {
                setTeamName(teamResponse.data.team_name);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error checking Slack connection:", error);
      }
    };

    checkSlackConnection();
  }, [user]);

  const handleConnect = async () => {
    await network.post(
      `/api/slack/store-user`,
      {
        auth0_user_id: user?.auth0_user_id,
      },
      {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      },
      clientError
    );
    // todo this should happen on the API
    // const slackOAuthUrl = `${env.APP_SLACK_AUTH_URL}?client_id=${env.APP_SLACK_CLIENT_ID}&scope=${env.APP_SLACK_SCOPE}&redirect_uri=/api/slack/callback&team=`;
    // window.location.href = slackOAuthUrl;
  };

  const handleReset = async () => {};

  if (connected) {
    return (
      <Group>
        <Button variant="subtle" color="white" leftSection={<IconCheck />}>
          Connected to the {teamName} workspace
        </Button>
        <Button onClick={handleReset} variant="subtle">
          Reset Connection
        </Button>
      </Group>
    );
  }

  return (
    <Button onClick={handleConnect} variant="primary">
      Connect
    </Button>
  );
}
