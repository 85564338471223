import { archivedAtField } from "../fields/archived_at";
import { createdAtField } from "../fields/created_at";
import { idField } from "../fields/id";
import { nameField } from "../fields/name";
import { slugField } from "../fields/slug";
import { updatedAtField } from "../fields/updated_at";
import { EntityDocs } from "../types/docs";
import {
  entity,
  EntityType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";

// AI Provider fields
export const fields = [
  archivedAtField,
  createdAtField,
  idField,
  nameField,
  slugField,
  updatedAtField,
] satisfies FieldList;

// AI Provider primary key fields
export const primaryKeyFields = [idField] satisfies PrimaryKeyFieldTuple;

// Docs
export const docs: EntityDocs = {
  collection: {
    summary: "List AI providers, with id, name, and slug fields",
  },
  item: { summary: "Perform operations on an ai provider." },
};

export const aiProviderEntity = entity({
  api: {
    collectionPath: "ai/providers",
    itemPath: "ai/providers/[id]",
  },
  docs,
  name: "AI Provider",
  fields,
  primaryKeyFields,
  tableName: "ai_providers",
});

export type AIProviderEntity = EntityType<
  typeof aiProviderEntity.fields,
  typeof aiProviderEntity.primaryKeyFields
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleAIProvider: AIProviderEntity = {
  id: 987,
  name: "BCD Inc",
  slug: "bcd-inc",
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
