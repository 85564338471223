import Markdown from "markdown-to-jsx";
import { Link } from "react-router-dom";

type MarkdownProps = {
  content: string;
};

export function MarkdownRenderer({ content }: MarkdownProps) {
  return (
    <Markdown
      options={{
        overrides: {
          a: CustomLink,
        },
      }}
    >
      {content}
    </Markdown>
  );
}

function CustomLink({
  href,
  children,
  ...rest
}: {
  href?: string;
  children: React.ReactNode;
}) {
  return href?.startsWith?.("/") ? (
    <Link to={href}>{children}</Link>
  ) : (
    <a
      {...rest}
      href={href}
      target={href?.startsWith?.("#") ? "_self" : "_blank"}
    >
      {children}
    </a>
  );
}
