import { UserEntity } from "../entities/user.js";

/**
 * Capitalizes the first letter of a given string.
 *
 * @param str - The string to capitalize.
 * @returns The string with the first letter capitalized.
 */
export function capitalizeFirstLetter(str: string): string {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Extracts the first and last initials from a full name.
 *
 * @param name - The full name of the user.
 * @returns A string containing the first and last initials.
 */
export function getInitials(name: string): string {
  if (!name) return "";

  const nameParts = name.trim().split(" ").filter(Boolean);

  if (nameParts.length === 0) return "";

  const firstInitial = capitalizeFirstLetter(nameParts[0].charAt(0));

  let lastInitial = "";

  if (nameParts.length === 1) {
    // If only one name part exists, use the first two letters
    lastInitial = capitalizeFirstLetter(nameParts[0].charAt(1)) || "";
  } else {
    lastInitial = capitalizeFirstLetter(
      nameParts[nameParts.length - 1].charAt(0)
    );
  }

  return `${firstInitial}${lastInitial}`;
}

export function getUserInitials(user?: UserEntity): string {
  return getInitials(
    user?.given_name || user?.family_name
      ? `${user?.given_name} ${user?.family_name}`
      : user?.nickname ?? user?.email ?? "-"
  );
}

export function getEmailDomain(email?: string): string {
  if (!email) return "";
  const parts = email.split("@");
  return parts.length === 2 ? parts[1] : "";
}
