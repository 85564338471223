import { Card, Text, UnstyledButton } from "@mantine/core";
import { useCallback } from "react";
import {
  createMemoryRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";

import { AdminNotFound } from "./admin/modules/AdminNotFound.js";
import { AdminWelcome } from "./admin/modules/AdminWelcome.js";
import { AdminContext } from "./contexts/AdminContext.js";
import { useSessionState } from "./hooks/useSessionState.js";

import { OrganizationEntity } from "../common/entities/organization.js";
import { OrganizationUserEntity } from "../common/entities/organizationUser.js";
import { UserEntity } from "../common/entities/user.js";
import { LEDGERAI_SLUG } from "../common/fields/slug.js";
import { AdminAI } from "./admin/modules/AdminAI.js";
import { AdminDocument } from "./admin/modules/AdminDocument.js";
import { withPathPersistence } from "./admin/modules/withPathPersistence.js";
import { PrimaryColor } from "./theme.js";

import classes from "./admin/AdminApp.css.js";

const adminAppRouter = createMemoryRouter(
  [{ path: "*", Component: AdminAppRoot }],
  {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

const adminAppRoutes = (
  <Routes>
    <Route path="/" Component={withPathPersistence(AdminWelcome)} />
    <Route path="/ai" Component={withPathPersistence(AdminAI)} />
    <Route
      path="/docs/:document_id"
      Component={withPathPersistence<{ document_id: string }>(AdminDocument)}
    />
    <Route path="*" Component={withPathPersistence(AdminNotFound)} />
  </Routes>
);

export function AdminApp({
  organization,
  organizationUser,
  user,
}: {
  organization: OrganizationEntity;
  organizationUser: OrganizationUserEntity;
  user: UserEntity;
}) {
  const [isExpanded, setIsExpanded] = useSessionState(".admin/expanded", false);

  const closeAdmin = useCallback(() => {
    setIsExpanded(false);
  }, [setIsExpanded]);

  if (organization.slug !== LEDGERAI_SLUG) {
    return null; // Only LedgerAI organization members can access admin features
  }

  if (!isExpanded) {
    return (
      <UnstyledButton
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1000,
        }}
        onClick={() => {
          setIsExpanded(true);
        }}
      >
        <AdminContext.Provider
          value={{ closeAdmin, organization, organizationUser, user }}
        >
          <Card
            bg={PrimaryColor}
            className={[
              classes.wrapper,
              "collapsed" satisfies typeof classes.collapsed,
            ].join(" ")}
            h={"auto"}
            p={0}
            shadow="lg"
            w="auto"
            withBorder
          >
            <Text
              size="sm"
              fw={500}
              p="xs"
              c="var(--mantine-color-org_serviceProvider_primary-1)"
            >
              {organization.name} Admin
            </Text>
          </Card>
        </AdminContext.Provider>
      </UnstyledButton>
    );
  }

  return (
    <AdminContext.Provider
      value={{ closeAdmin, organization, organizationUser, user }}
    >
      <Card
        bg={PrimaryColor}
        className={[
          classes.wrapper,
          "expanded" satisfies typeof classes.expanded,
        ].join(" ")}
        h={"auto"}
        p={0}
        shadow="lg"
        w={"calc(100vw - 480px)"}
        withBorder
      >
        <div className={classes.content}>
          <RouterProvider
            router={adminAppRouter}
            future={{ v7_startTransition: true }}
          />
        </div>
      </Card>
    </AdminContext.Provider>
  );
}

export function AdminAppRoot() {
  return <>{adminAppRoutes}</>;
}
