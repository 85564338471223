import {
  Box,
  Button,
  Divider,
  Group,
  Loader,
  Stack,
  Title,
} from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { UserEntity } from "../../../common/entities/user.js";
import {
  organizationUserClient,
  userClient,
} from "../../../common/utils/entityClient.js";
import { clientError } from "../../utils/clientError.js";
import { UserModal } from "../Modals/UserModal.js";
import { UsersTable } from "../Tables/UsersTable.js";

interface UserManagementProps {
  organizationId: number;
}

export function UserManagement({ organizationId }: UserManagementProps) {
  const [users, setUsers] = useState<UserEntity[] | null>(null);
  const [createModalOpened, setCreateModalOpened] = useState(false);

  const fetchUsers = useCallback(async () => {
    const response = await userClient(clientError).list({
      organization_id: organizationId,
    });
    if (response.success && "data" in response && "data" in response.data) {
      setUsers(response.data.data);
    }
  }, [organizationId]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleCreate = async (newUser: Partial<UserEntity>) => {
    const response = await userClient(clientError).create({
      ...newUser,
      organization_id: organizationId,
    });
    if (response.success && "data" in response && "data" in response.data) {
      await fetchUsers();
      setCreateModalOpened(false);
    }
  };

  const handleDelete = async (user: UserEntity) => {
    if (!user.id) return;

    const response = await organizationUserClient(clientError)
      .item(organizationId, user.id)
      .delete();
    if (response.success) {
      await fetchUsers();
    }
  };

  return (
    <Stack gap={0} style={{ height: "fit-content" }}>
      <Box py="md" px="lg">
        <Group justify="space-between" align="center">
          <Title order={4}>People</Title>
          <Button onClick={() => setCreateModalOpened(true)}>Add User</Button>
        </Group>
      </Box>
      <Divider />

      {Array.isArray(users) ? (
        <UsersTable users={users} onDelete={handleDelete} />
      ) : (
        <Box
          style={{
            alignContent: "flex-end",
            alignItems: "center",
            display: "flex",
            height: "fit-content",
            justifyContent: "center",
            overflowY: "auto",
            padding: "100px",
          }}
        >
          <Loader />
        </Box>
      )}

      <UserModal
        opened={createModalOpened}
        onClose={() => setCreateModalOpened(false)}
        onSubmit={handleCreate}
        editMode={false}
      />
    </Stack>
  );
}
