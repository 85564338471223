import { IntegerField } from "../types/field";

export const userIdFieldRequired: IntegerField<{
  final: true;
  name: "user_id";
  required: true;
}> = {
  final: true,
  label: "User ID",
  name: "user_id",
  required: true,
  type: "integer",
};

export const userIdFieldOptional: IntegerField<{
  final: true;
  name: "user_id";
}> = {
  final: true,
  label: "User ID",
  name: "user_id",
  type: "integer",
};
