import { IntegerField } from "../types/field";

export const aiProviderIdField: IntegerField<{
  name: "ai_provider_id";
  required: true;
}> = {
  label: "Provider ID",
  name: "ai_provider_id",
  required: true,
  type: "integer",
};
