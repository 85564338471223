import { useContext } from "react";

import { AsideContext } from "../contexts/AsideContext.js";

export const useAside = () => {
  const context = useContext(AsideContext);
  if (!context) {
    throw new Error("useAside must be used within an AsideProvider");
  }
  return context;
};
