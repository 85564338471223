import { useMemo, useState } from "react";

export function useSessionState<T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] {
  const initialState = useMemo(() => {
    const item = sessionStorage.getItem(key);
    if (item === null) {
      return initialValue;
    }
    try {
      return JSON.parse(item);
    } catch {
      return initialValue;
    }
  }, [key, initialValue]);

  const [state, _setState] = useState<T>(initialState);

  function setState(value: T) {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
      _setState(value);
    } catch (error) {
      console.error(`Failed to save state to sessionStorage:`, error);
    }
  }

  return [typeof state === "undefined" ? initialState : state, setState];
}
