import { ActionIcon } from "@mantine/core";
import {
  IconLayoutSidebarRight,
  IconLayoutSidebarRightFilled,
} from "@tabler/icons-react";
import { useAside } from "../../hooks/useAside.js";

export function AsideToggleButton() {
  const { opened, openPennyChat, closeAside } = useAside();

  const toggleAside = () => {
    if (opened) {
      closeAside();
    } else {
      openPennyChat();
    }
  };

  return (
    <ActionIcon onClick={toggleAside} variant="default" size="lg">
      {opened ? <IconLayoutSidebarRightFilled /> : <IconLayoutSidebarRight />}
    </ActionIcon>
  );
}
