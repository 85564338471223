import { Text } from "@mantine/core";
import { AdminLayout } from "./../layouts/AdminLayout.js";

export function AdminNotFound() {
  return (
    <AdminLayout>
      <Text>Welcome to the admin app</Text>
    </AdminLayout>
  );
}
