import { EntityDocs } from "./docs";
import {
  BooleanField,
  Field,
  FieldMetadata,
  IntegerField,
  IntegerRangeField,
  ObjectField,
  StringField,
  TimestampField,
} from "./field";

type ModifyFieldType<
  M extends Field<FieldMetadata>,
  T
> = M["array"] extends true ? T[] : T;

type FieldTypeToTypeScriptType<T extends Field<FieldMetadata>> =
  ModifyFieldType<
    T,
    T extends BooleanField<FieldMetadata>
      ? boolean
      : T extends IntegerField<FieldMetadata>
      ? number
      : T extends IntegerRangeField<FieldMetadata>
      ? [number, number]
      : T extends ObjectField<FieldMetadata>
      ? object
      : T extends StringField<FieldMetadata>
      ? string
      : T extends TimestampField<FieldMetadata>
      ? string
      : never
  >;

type OptionalFields<T extends readonly Field<FieldMetadata>[]> = {
  [K in T[number] as K extends Field<FieldMetadata>
    ? K["required"] extends true
      ? never
      : K["name"]
    : never]?: FieldTypeToTypeScriptType<K>;
};

type RequiredFields<T extends readonly Field<FieldMetadata>[]> = {
  [K in T[number] as K extends Field<FieldMetadata>
    ? K["required"] extends true
      ? K["name"]
      : never
    : never]: FieldTypeToTypeScriptType<K>;
};

type OptionalCreatableFields<T extends readonly Field<FieldMetadata>[]> = {
  [K in T[number] as K extends Field<FieldMetadata>
    ? K["required"] extends true
      ? never
      : K["auto"] extends true
      ? never
      : K["name"]
    : never]?: FieldTypeToTypeScriptType<K>;
};

type RequiredCreatableFields<T extends readonly Field<FieldMetadata>[]> = {
  [K in T[number] as K extends Field<FieldMetadata>
    ? K["required"] extends true
      ? K["auto"] extends true
        ? never
        : K["name"]
      : never
    : never]: FieldTypeToTypeScriptType<K>;
};
type OptionalUpdateableFields<T extends readonly Field<FieldMetadata>[]> = {
  [K in T[number] as K extends Field<FieldMetadata>
    ? K["required"] extends true
      ? never
      : K["auto"] extends true
      ? never
      : K["final"] extends true
      ? never
      : K["name"]
    : never]?: FieldTypeToTypeScriptType<K>;
};

type RequiredUpdateableFields<T extends readonly Field<FieldMetadata>[]> = {
  [K in T[number] as K extends Field<FieldMetadata>
    ? K["required"] extends true
      ? K["auto"] extends true
        ? never
        : K["final"] extends true
        ? never
        : K["name"]
      : never
    : never]: FieldTypeToTypeScriptType<K>;
};

export type EntityTypeFromFields<T extends readonly Field<FieldMetadata>[]> =
  RequiredFields<T> & OptionalFields<T>;

export type EntityCreateTypeFromFields<
  T extends readonly Field<FieldMetadata>[]
> = RequiredCreatableFields<T> & OptionalCreatableFields<T>;

export type EntityUpdateTypeFromFields<
  T extends readonly Field<FieldMetadata>[]
> = RequiredUpdateableFields<T> & OptionalUpdateableFields<T>;

export type FieldList = readonly Field<FieldMetadata>[];

export type PrimaryKeyFieldTuple =
  | readonly [Field<FieldMetadata>]
  | readonly [Field<FieldMetadata>, Field<FieldMetadata>]
  | readonly [Field<FieldMetadata>, Field<FieldMetadata>, Field<FieldMetadata>];

type EntityValidator<T extends FieldList> = (data: EntityTypeFromFields<T>) => {
  data: EntityTypeFromFields<T>;
  errors: Record<keyof EntityTypeFromFields<T>, string>;
  overallErrors: string[];
};

export type EntityCreateValidator<T extends FieldList> = (
  data: EntityCreateTypeFromFields<T>
) => {
  data: EntityCreateTypeFromFields<T>;
  errors: Record<keyof EntityCreateTypeFromFields<T>, string>;
  overallErrors: string[];
};

export type EntityUpdateValidator<T extends FieldList> = (
  data: EntityUpdateTypeFromFields<T>
) => {
  data: EntityUpdateTypeFromFields<T>;
  errors: Record<keyof EntityUpdateTypeFromFields<T>, string>;
  overallErrors: string[];
};

export interface EntityApiOptions {
  collectionPath: string;
  itemPath: string;
  specialId?: string;
  list?: {
    missingFilterImpliesNull?: boolean;
  };
}

export interface Entity<
  T extends FieldList,
  U extends PrimaryKeyFieldTuple,
  N extends string = string
> {
  api: EntityApiOptions;
  docs: EntityDocs;
  name: N;
  fields: T;
  primaryKeyFields: U;
  tableName: string;
  validate: EntityValidator<T>;
  validateCreate: EntityCreateValidator<T>;
  validateUpdate: EntityUpdateValidator<T>;
}

export function entity<
  T extends FieldList,
  U extends PrimaryKeyFieldTuple,
  N extends string = string
>({
  api,
  docs,
  fields,
  name,
  primaryKeyFields,
  tableName,
}: {
  api: EntityApiOptions;
  docs: EntityDocs;
  fields: T;
  name: N;
  primaryKeyFields: U;
  tableName: string;
}): Entity<T, U, N> {
  for (const primaryKeyField of primaryKeyFields) {
    if (!fields.find((f) => f.name === primaryKeyField.name)) {
      throw new Error(
        `Error in ${name} entity definition: ${JSON.stringify(
          primaryKeyField.name
        )} is not in the entity fields list.`
      );
    }
  }
  function validate(data: EntityTypeFromFields<T>) {
    const errors: Record<keyof EntityTypeFromFields<T>, string> = {} as Record<
      keyof EntityTypeFromFields<T>,
      string
    >;
    const overallErrors: string[] = [];
    return { data, errors, overallErrors };
  }

  function validateArrayField(
    value: FieldTypeToTypeScriptType<Field<FieldMetadata>>[],
    field: Field<FieldMetadata>,
    errors: Record<string, string>
  ) {
    switch (field.type) {
      case "boolean":
        for (const item of value) {
          if (typeof item !== "boolean") {
            errors[field.name] = `${
              field.name
            } must be an array of booleans, found a ${typeof item}`;
          }
        }
        break;
      case "integer":
        for (const item of value) {
          if (typeof item !== "number") {
            errors[field.name] = `${
              field.name
            } must be an array of numbers, found a ${typeof item}`;
          }
        }
        break;
      case "string":
        for (const item of value) {
          if (typeof item !== "string") {
            errors[field.name] = `${
              field.name
            } must be an array of strings, found a ${typeof item}`;
          }
        }
        break;
      default:
        throw new Error(`Unknown array field type: ${field.type}`);
    }
  }

  function validateCreate(data: EntityCreateTypeFromFields<T>) {
    const errors: Record<keyof EntityCreateTypeFromFields<T>, string> =
      {} as Record<keyof EntityCreateTypeFromFields<T>, string>;
    const overallErrors: string[] = [];
    for (const field of fields) {
      if (field.name in data) {
        if (
          !field.required &&
          (data[field.name] === null || data[field.name] === undefined)
        ) {
          continue;
        }
        const value = data[field.name];
        if (field.array) {
          if (!Array.isArray(value)) {
            errors[field.name] = `${field.label} must be an array`;
            continue;
          } else {
            validateArrayField(value, field, errors);
          }
        } else {
          switch (field.type) {
            case "boolean":
              if (typeof value !== "boolean") {
                errors[field.name] = `${field.label} must be a boolean`;
              }
              break;
            case "integer":
              if (typeof value !== "number") {
                errors[field.name] = `${field.label} must be a number`;
              }
              break;
            case "string":
              if (typeof value !== "string") {
                errors[field.name] = `${field.label} must be a string`;
              }
              if (field.required && value.trim().length === 0) {
                errors[field.name] = `${field.label} is required`;
              }
              break;
          }
        }
      } else if (field.required && !field.auto) {
        errors[field.name] = `${field.label} is required`;
      }
    }
    return { data, errors, overallErrors };
  }

  function validateUpdate(data: EntityUpdateTypeFromFields<T>) {
    const errors: Record<keyof EntityUpdateTypeFromFields<T>, string> =
      {} as Record<keyof EntityUpdateTypeFromFields<T>, string>;
    const overallErrors: string[] = [];
    for (const field of fields) {
      if (field.name in data) {
        if (
          !field.required &&
          (data[field.name] === null || data[field.name] === undefined)
        ) {
          continue;
        }
        const value = data[field.name];
        if (field.array) {
          if (!Array.isArray(value)) {
            errors[field.name] = `${field.label} must be an array`;
            continue;
          } else {
            validateArrayField(value, field, errors);
          }
        } else {
          switch (field.type) {
            case "boolean":
              if (typeof value !== "boolean") {
                errors[field.name] = `${field.label} must be a boolean`;
              }
              break;
            case "integer":
              if (typeof value !== "number") {
                errors[field.name] = `${field.label} must be a number`;
              }
              break;
            case "string":
              if (typeof value !== "string") {
                errors[field.name] = `${field.label} must be a string`;
              }
              if (field.required && value.trim().length === 0) {
                errors[field.name] = `${field.label} is required`;
              }
              break;
          }
        }
      }
    }
    return { data, errors, overallErrors };
  }

  return {
    api,
    docs,
    fields,
    name,
    primaryKeyFields,
    tableName,
    validate,
    validateCreate,
    validateUpdate,
  };
}

type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

export type EntityType<
  T extends FieldList,
  U extends PrimaryKeyFieldTuple,
  N extends string = string
> = Prettify<ReturnType<Entity<T, U, N>["validate"]>["data"]>;

export type EntityCreateType<
  T extends FieldList,
  U extends PrimaryKeyFieldTuple,
  N extends string = string
> = Prettify<ReturnType<Entity<T, U, N>["validateCreate"]>["data"]>;

export type EntityUpdateType<
  T extends FieldList,
  U extends PrimaryKeyFieldTuple,
  N extends string = string
> = Prettify<ReturnType<Entity<T, U, N>["validateUpdate"]>["data"]>;

export type EntityPrimaryKeyType<U extends PrimaryKeyFieldTuple> = {
  [K in keyof U]: FieldTypeToTypeScriptType<U[K]> | null;
};
