import { Avatar, AvatarProps } from "@mantine/core";
import { useEffect, useState } from "react";

import { getUserName, UserEntity } from "../../../common/entities/user.js";
import { getUserInitials } from "../../../common/utils/textUtils.js";
import {
  fetchUserCached,
  USER_PROFILE_CACHE,
} from "../Excelente/UserProfile.js";

export function UserAvatar({
  user,
  userId,
  ...props
}: { user?: UserEntity; userId?: number } & AvatarProps) {
  const [userData, setUserData] = useState<UserEntity | undefined>(
    user ??
      (typeof userId === "number" ? USER_PROFILE_CACHE.get(userId) : undefined)
  );
  useEffect(() => {
    if (typeof userId === "number") {
      fetchUserCached(userId, userData, setUserData);
    }
  }, [userId]);

  return (
    <Avatar
      src={userData?.picture}
      name={userData ? getUserInitials(userData) : ""}
      alt={userData ? getUserName(userData) : ""}
      title={userData ? getUserName(userData) : ""}
      {...props}
    />
  );
}
