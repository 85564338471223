import { css } from "../../utils/css.js";

export default css<{
  container: string;
}>`
  .container {
    border-right: 1px solid var(--app-shell-border-color);
    flex-grow: 0;
    flex-shrink: 0;
    height: calc(100vh - var(--app-shell-header-height));
    overflow-x: hidden;
    overflow-y: auto;
    width: 300px;
  }

  .container a.!active {
    font-weight: bold;
  }
`;
