import { Badge, Card, Stack } from "@mantine/core";
import React, { useState } from "react";

import { useAdmin } from "../../contexts/AdminContext.js";
import { AdminFooterToolBar } from "../modules/AdminFooterToolBar.js";
import { AdminToolBar } from "../modules/AdminToolBar.js";

interface LayoutProps {
  children: React.ReactNode;
}

export const AdminLayout = ({ children }: LayoutProps) => (
  <Stack gap={0}>
    <AdminCloseButton />
    <AdminToolBar />
    <Card style={{ maxHeight: "640px", overflowY: "auto" }}>{children}</Card>
    <AdminFooterToolBar />
  </Stack>
);

function AdminCloseButton() {
  const { closeAdmin, organization } = useAdmin();

  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <Badge
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      fw={600}
      size="lg"
      p="lg"
      bg={
        isMouseOver
          ? "var(--mantine-color-red-8)"
          : "var(--mantine-color-dark-5)"
      }
      c="var(--mantine-color-gray-5)"
      onClick={closeAdmin}
      style={{
        boxShadow: "var(--paper-shadow)",
        border: `1px solid ${organization.theme_primary_color}`,
        borderRadius: "0.75rem",
        cursor: "pointer",
        left: "50%",
        position: "absolute",
        top: "-24px",
        transform: "translateX(-50%)",
      }}
    >
      {isMouseOver ? "Close" : "ADMIN"}
    </Badge>
  );
}
