import { useChat } from "@ai-sdk/react";
import {
  AvatarGroup,
  Box,
  ComboboxItemGroup,
  Group,
  Loader,
  rem,
  Select,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandAmazon,
  IconBrandOpenai,
  IconBrandXFilled,
  IconCode,
} from "@tabler/icons-react";
import { useEffect, useMemo, useRef } from "react";

import {
  AI_PROVIDER_AMAZON_BEDROCK,
  AI_PROVIDER_OPENAI,
  AI_PROVIDER_XAI,
  SUPPORTED_AI_PROVIDERS,
} from "../../../common/configuration/aiProviders.js";
import { Conversation } from "../../../common/core/messages.js";
import { AIModelEntity } from "../../../common/entities/aiModel.js";
import { AIProviderEntity } from "../../../common/entities/aiProvider.js";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { UserEntity } from "../../../common/entities/user.js";
import {
  aiModelClient,
  aiProviderClient,
} from "../../../common/utils/entityClient.js";
import { UserAvatar } from "../../components/Avatars/UserAvatar.js";
import { MessageDisplay } from "../../components/Messages/MessageDisplay.js";
import { useSessionState } from "../../hooks/useSessionState.js";
import { clientError } from "../../utils/clientError.js";
import { ChatInput } from "./ChatInput.js";

export interface PennyConversationState {
  conversation?: Conversation;
}

function getAIProviderIcon(aiProviderSlug?: string) {
  return typeof aiProviderSlug === "string"
    ? {
        [AI_PROVIDER_AMAZON_BEDROCK]: <IconBrandAmazon size="16" />,
        [AI_PROVIDER_OPENAI]: <IconBrandOpenai size="16" />,
        [AI_PROVIDER_XAI]: <IconBrandXFilled size="16" />,
      }[aiProviderSlug]
    : undefined ?? <IconCode size="16" />;
}

function SelectModel({
  model,
  setModel,
}: {
  model: AIModelEntity | null;
  setModel: (model: AIModelEntity | null) => void;
}) {
  const [aiModels, setAIModels] = useSessionState<AIModelEntity[]>(
    "ai-models",
    []
  );
  const [aiProviders, setAIProviders] = useSessionState<AIProviderEntity[]>(
    "ai-providers",
    []
  );

  useEffect(
    function () {
      async function load() {
        if (aiModels.length > 0 && aiProviders.length > 0) {
          if (!model) {
            console.log(
              "Setting default model to gpt-4-turbo",
              model,
              aiModels.find((m) => m.name === "gpt-4-turbo")
            );
            setModel(aiModels.find((m) => m.name === "gpt-4-turbo") || null);
          }
          return;
        }
        const [listModelsResponse, listProvidersResponse] = await Promise.all([
          aiModelClient(clientError).list(),
          aiProviderClient(clientError).list(),
        ]);
        if (listModelsResponse.success) {
          if (listModelsResponse.data.success) {
            setAIModels(listModelsResponse.data.data);
            if (!model) {
              console.log(
                "Setting default model to gpt-4-turbo",
                model,
                listModelsResponse.data.data.find(
                  (m) => m.name === "gpt-4-turbo"
                )
              );
              setModel(
                listModelsResponse.data.data.find(
                  (m) => m.name === "gpt-4-turbo"
                ) || null
              );
            }
          } else {
            return;
          }
        } else {
          return;
        }
        if (listProvidersResponse.success) {
          if (listProvidersResponse.data.success) {
            setAIProviders(listProvidersResponse.data.data);
          }
        }
      }
      load().catch((e) => console.error(e));
    },
    [model, setModel]
  );

  const groupedData = useMemo(
    () =>
      aiModels && aiProviders
        ? aiProviders
            .filter((provider) =>
              SUPPORTED_AI_PROVIDERS.includes(provider.slug!)
            )
            .map(
              (
                provider
              ): ComboboxItemGroup<{ value: string; label: string }> => ({
                group: provider.name!,
                items: aiModels
                  .filter((model) => model.ai_provider_id === provider.id)
                  .map((model) => ({
                    value: model.id!.toString(10),
                    label: model.name!,
                  })),
              })
            )
        : [],
    [aiModels, aiProviders]
  );

  if (aiProviders.length === 0 || aiModels.length === 0) {
    return (
      <Group>
        <Loader size="xs" type="dots" />
        <Select disabled />
      </Group>
    );
  }

  return (
    <Select
      leftSection={getAIProviderIcon(
        aiProviders.find((p) => p.id === model?.ai_provider_id)?.slug
      )}
      placeholder="Select AI Model"
      data={groupedData}
      value={model?.id?.toString(10) || null}
      onChange={(value) => {
        const selectedModel = aiModels.find(
          (m) => m.id!.toString(10) === value
        );
        setModel(selectedModel || null);
      }}
      searchable
    />
  );
}

export function PennyConversation({
  state,
  setState,
  user,
  users,
  organization,
  customer,
  serviceProvider,
}: {
  state: PennyConversationState;
  setState: (state: PennyConversationState) => void;
  user: UserEntity;
  users: UserEntity[];
  organization: OrganizationEntity;
  customer?: OrganizationEntity;
  serviceProvider: OrganizationEntity;
}) {
  // Add function to get time-based greeting
  const getTimeBasedGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good morning";
    if (hour < 17) return "Good afternoon";
    return "Good evening";
  };

  const [model, setModel] = useSessionState<AIModelEntity | null>(
    "ai-model",
    null
  );

  const numMessagesProcessed = useRef(0);

  const initialThreadMessages = state.conversation?.threads[0]?.messages ?? [];

  const initialMessages = state.conversation
    ? Array.from(
        [
          ...(initialThreadMessages.length > 0
            ? initialThreadMessages
            : [
                {
                  id: "welcome-message",
                  role: "assistant" as const,
                  content: `${getTimeBasedGreeting()} ${
                    user?.given_name || "there"
                  }! 👋 I'm Penny, your AI assistant${
                    organization ? ` for ${organization.name}` : ""
                  }. I can help you with various tasks, answer questions, and provide assistance. Feel free to ask me anything!`,
                },
              ]),
          ...initialThreadMessages,
        ]
          .reduce((messages, message) => {
            // deduplicate messages by id; @todo investigate why this is necessary
            messages.set(message.id, message);
            return messages;
          }, new Map())
          .values()
      )
    : [];

  console.log("initialMessages", initialMessages);

  const {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    isLoading,
    addToolResult,
  } = useChat({
    api: "/api/chat",
    body: {
      conversationId: state.conversation?.id,
      threadId: state.conversation?.threads[0]?.id,
      customer,
      model,
      serviceProvider,
      user,
    },
    onError: (error) => {
      if (error?.message.startsWith("Failed to parse stream string.")) {
        return;
      }
      clientError(error?.message ?? "Unknown error");
    },
    onResponse: (response) => {
      // Clone the response to create a second stream
      const clonedResponse = response.clone();

      // Read the cloned response as a stream
      const reader = clonedResponse.body?.getReader();
      if (!reader) return;

      // Function to process the stream
      const processStream = async () => {
        try {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            // Convert the stream chunk to text
            const chunk = new TextDecoder().decode(value);

            // Look for the data: prefix that contains our JSON
            if (chunk.includes("data: ")) {
              const jsonStr = chunk.split("data: ")[1];
              try {
                console.log("about to parse jsonStr", jsonStr);
                const data = JSON.parse(jsonStr);
                console.log("Conversation ID:", data.conversation_id);
                // Update the state with the conversation ID
                setState({
                  ...state,
                  conversation: {
                    created_at: state.conversation?.created_at ?? new Date(),
                    threads: state.conversation?.threads ?? [],
                    id: data.conversation_id,
                    title: state.conversation?.title ?? "",
                    updated_at: state.conversation?.updated_at ?? new Date(),
                  },
                });
              } catch (e) {
                console.error("Failed to parse JSON:", e);
              }
            }
          }
        } catch (error) {
          console.error("Stream reading error:", error);
        }
      };

      processStream();
    },
    initialMessages,
  });

  useEffect(() => {
    if (messages.length > numMessagesProcessed.current) {
      const newMessages = messages.slice(numMessagesProcessed.current);
      numMessagesProcessed.current = messages.length;
      console.log("newMessages", newMessages);
      setState({
        ...state,
        conversation: state.conversation
          ? {
              ...state.conversation,
              threads: state.conversation.threads.concat([
                {
                  conversation_id: state.conversation.id,
                  created_at: new Date(),
                  id: state.conversation.id * 100 + newMessages.length,
                  updated_at: new Date(),
                  messages: newMessages,
                },
              ]),
            }
          : undefined,
      });
    }
  }, [messages]);

  // Reference to the chat display container
  const chatContainerRef = useRef<HTMLDivElement>(null);

  // Function to scroll to bottom
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const scrollElement = chatContainerRef.current;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  };

  // Scroll when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Add a small delay to ensure content is rendered
  useEffect(() => {
    const timeoutId = setTimeout(scrollToBottom, 100);
    return () => clearTimeout(timeoutId);
  }, [messages]);

  return (
    <>
      <Group justify="space-between" p="md">
        <SelectModel model={model} setModel={setModel} />
        <Tooltip.Group openDelay={300} closeDelay={100}>
          <AvatarGroup>
            {users.map?.((user) => (
              <Tooltip key={user.id} label={user.given_name} withArrow>
                <UserAvatar user={user} size={35} radius={80} key={user.id} />
              </Tooltip>
            ))}
          </AvatarGroup>
        </Tooltip.Group>
      </Group>
      <Box
        style={{
          display: "flex",
          flexGrow: "1",
          flexDirection: "column",
          maxHeight: "calc(100vh - 120px - var(--app-shell-header-height))",
          minHeight: "calc(100vh - 120px - var(--app-shell-header-height))",
          borderTop: "1px solid var(--app-shell-border-color)",
        }}
      >
        <Box
          p="xl"
          ref={chatContainerRef}
          style={{
            flex: 1,
            overflowY: "auto",
            alignContent: "flex-end",
          }}
        >
          {(messages ?? []).map((message, index) => (
            <MessageDisplay
              addToolResult={(toolCallId, result) =>
                addToolResult({ toolCallId, result })
              }
              focusable={false}
              key={message.id}
              message={
                index in initialMessages ? initialMessages[index] : message
              }
              user={user} // note that this is only used if the message has no user information
              streamFromStatic
            />
          ))}
        </Box>
        <Box
          p="md"
          style={{
            height: rem(100),
            borderTop: "1px solid var(--app-shell-border-color)",
            position: "relative",
          }}
        >
          <ChatInput
            input={input}
            setInput={handleInputChange}
            onSend={handleSubmit}
            loading={isLoading}
          />
        </Box>
      </Box>
    </>
  );
}
