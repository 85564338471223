import { Button, Group, Loader, Tooltip } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { RouteProps, useParams } from "react-router-dom";
import { network } from "../../../common/utils/network.js";
import { MarkdownRenderer } from "../../pages/penny/MarkdownRenderer.js";
import { clientError } from "../../utils/clientError.js";
import { AdminLayout } from "../layouts/AdminLayout.js";

export function AdminDocument({
  document_id,
}: { document_id: string } & RouteProps) {
  console.log({ document_id });

  const [doc, setDoc] = useState<{ markdown: string } | null>(null);
  const [reload, setReload] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();

  console.log({ params });

  useEffect(() => {
    async function load() {
      try {
        setIsLoading(true);
        const document_id = 0;
        const docResponse = await network.get<{ markdown: string }>(
          `/api/docs/${document_id}`,
          {},
          clientError
        );
        if (docResponse.success) {
          setDoc(docResponse.data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
    load().catch((e) => console.error(e));
  }, [params, reload]);

  return (
    <AdminLayout>
      <Group>
        <Tooltip label="Reload document" position="left" withArrow zIndex={301}>
          <Button
            disabled={isLoading}
            size="sm"
            variant="outline"
            style={{
              flexShrink: "0",
              position: "fixed",
              right: "20px",
              top: "64px",
              paddingInline: "4px",
            }}
            onClick={() => setReload((x) => x + 1)}
          >
            <IconRefresh />
          </Button>
        </Tooltip>
      </Group>
      {doc ? (
        <MarkdownRenderer content={doc.markdown} />
      ) : (
        <Loader type="dots" />
      )}
    </AdminLayout>
  );
}
