import { Tooltip, UnstyledButton } from "@mantine/core";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { ServiceProviderPrimaryColor } from "../../theme.js";
import { OrganizationAvatar } from "../Avatars/OrganizationAvatar.js";

export function ServiceProviderMenu() {
  const { serviceProvider, isCustomerUser, customer } = useActiveStates();

  // Use active customer if available, otherwise use service provider
  const activeOrganization = customer?.id ? customer : serviceProvider;

  // Check if the current organization is the same as the service provider
  if (activeOrganization?.id === serviceProvider?.id) {
    return null; // Do not render the component
  }

  const tooltipLabel = isCustomerUser
    ? `This service is provided by ${
        serviceProvider?.name || "Service provider"
      }.`
    : `You are navigating ${activeOrganization.name} as a member of ${
        serviceProvider?.name || "Service provider"
      }.`;

  return (
    <Tooltip
      multiline
      w={200}
      label={tooltipLabel}
      position="right"
      withArrow
      transitionProps={{ duration: 0 }}
    >
      <UnstyledButton style={{ cursor: "default" }}>
        <OrganizationAvatar
          organization={serviceProvider}
          variant="filled"
          size={45}
          radius={serviceProvider?.picture ? 0 : "sm"}
          color={ServiceProviderPrimaryColor}
        />
      </UnstyledButton>
    </Tooltip>
  );
}
