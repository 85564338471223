import { IntegerField } from "../types/field";

export const progressField: IntegerField<{
  name: "progress";
  required: true;
}> = {
  label: "Progress",
  name: "progress",
  required: true,
  type: "integer",
};
