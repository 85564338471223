import { StringField } from "../types/field";

export const labelField: StringField<{
  name: "label";
  required: true;
}> = {
  label: "Label",
  name: "label",
  required: true,
  type: "string",
};
