import { ComponentType, useEffect } from "react";
import { Params, useLocation, useNavigate } from "react-router-dom";

export function withPathPersistence<Props extends Params>(
  WrappedComponent: React.FC<Props>
): ComponentType {
  return function PathPersistenceComponent(props: Props) {
    const location = useLocation();
    const navigate = useNavigate();
    const g = globalThis as unknown as Window & {
      pathPersistenceFirstLoad?: boolean;
    };

    useEffect(() => {
      if (!g.pathPersistenceFirstLoad) {
        g.pathPersistenceFirstLoad = true;
        const lastPath = sessionStorage.getItem("lastPath");
        if (lastPath && lastPath !== location.pathname) {
          navigate(lastPath);
        }
        return;
      }
      sessionStorage.setItem("lastPath", location.pathname);
    }, [g, navigate, location.pathname]);

    return <WrappedComponent {...(props as Props)} />;
  } as ComponentType;
}
