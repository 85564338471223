import { AppShell, Group } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { StoredAuthProfile } from "../utils/storeAuthProfile.js";
import { AsideToggleButton } from "./Buttons/AsideToggleButton.js";
import { AccountMenu } from "./Menus/AccountMenu.js";
import { ColorSwitcherMenu } from "./Menus/ColorSwitcherMenu.js";
import { CustomerMenu } from "./Menus/CustomerMenu.js";

export function AppHeader() {
  const [allAccounts, setAllAccounts] = useState<StoredAuthProfile[]>([]);

  const updateAccount = useCallback((account: StoredAuthProfile) => {
    setAllAccounts((prev) => {
      const updatedAccounts = [
        ...prev.filter((a) => a.sessionId !== account.sessionId),
        account,
      ];
      localStorage.setItem("authProfiles", JSON.stringify(updatedAccounts));
      return updatedAccounts;
    });
  }, []);

  // Fetch real accounts from localStorage
  useEffect(() => {
    const storedProfiles = localStorage.getItem("authProfiles");
    if (storedProfiles) {
      try {
        const parsedProfiles: StoredAuthProfile[] = JSON.parse(storedProfiles);
        setAllAccounts(parsedProfiles);
      } catch (error) {
        console.error("Failed to parse authProfiles from localStorage:", error);
      }
    }
  }, []);

  return (
    <AppShell.Header>
      <Group justify="space-between" py="xs" px="md">
        <CustomerMenu />
        <Group gap="xs">
          <AccountMenu
            allAccounts={allAccounts}
            updateAccount={updateAccount}
          />
          <ColorSwitcherMenu />
          <AsideToggleButton />
        </Group>
      </Group>
    </AppShell.Header>
  );
}
