import { useCallback, useEffect, useRef, useState } from "react";
import { MetricEntity } from "../../common/entities/metric.js";
import { metricClient } from "../../common/utils/entityClient.js";
import { clientError } from "../utils/clientError.js";

interface MetricsParams {
  userId?: number;
  customerId?: number;
  providerId?: number;
}

export function useMetrics({ userId, customerId, providerId }: MetricsParams): {
  metrics: MetricEntity[];
  loading: boolean;
  error: string[] | undefined;
  refresh: () => void;
} {
  const [metrics, setMetrics] = useState<MetricEntity[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string[]>();

  const mostRecentUserId = useRef<number | undefined>(undefined);
  const mostRecentCustomerId = useRef<number | undefined>(undefined);
  const mostRecentProviderId = useRef<number | undefined>(undefined);

  useEffect(() => {
    setMetrics([]); // clear metrics when filters change
    mostRecentUserId.current = userId;
    mostRecentCustomerId.current = customerId;
    mostRecentProviderId.current = providerId;
  }, [userId, customerId, providerId]);

  const fetchMetrics = useCallback(async () => {
    if (!userId || (!customerId && !providerId)) {
      return;
    }

    setLoading(true);
    setError(undefined);

    try {
      const response = await metricClient(clientError).list({
        user_id: userId,
        customer_id: customerId,
        provider_id: providerId,
      });

      if (
        mostRecentUserId.current !== userId ||
        mostRecentCustomerId.current !== customerId ||
        mostRecentProviderId.current !== providerId
      ) {
        return;
      }

      if (response.success) {
        if (response.data.success) {
          setMetrics(response.data.data as MetricEntity[]);
        } else {
          setError(response.data.errors);
        }
      } else {
        setError([`HTTP ${response.status} ${response.error}`]);
      }
    } catch (e) {
      setError([(e as Error).message]);
    } finally {
      if (
        mostRecentUserId.current !== userId ||
        mostRecentCustomerId.current !== customerId ||
        mostRecentProviderId.current !== providerId
      ) {
        return;
      }

      setLoading(false);
    }
  }, [userId, customerId, providerId]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  return {
    metrics,
    loading,
    error,
    refresh: fetchMetrics,
  };
}
