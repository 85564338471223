import { Message } from "@ai-sdk/react";
import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Code,
  Group,
  Loader,
  Modal,
  Stack,
  Tabs,
  Text,
} from "@mantine/core";
import { useMemo, useState } from "react";

import ReactTimeAgo from "react-time-ago";
import { MessageEntity } from "../../../common/entities/message.js";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { OrganizationIntegrationTokenEntity } from "../../../common/entities/organizationIntegrationToken.js";
import { UserEntity } from "../../../common/entities/user.js";
import {
  INTEGRATION_LABELS,
  INTEGRATION_QUICKBOOKS,
} from "../../../common/fields/integration.js";
import { useAside } from "../../hooks/useAside.js";
import { MarkdownRenderer } from "../../pages/penny/MarkdownRenderer.js";
import { messageWithDefaultSwapsReact } from "./messageWithDefaultSwapsReact.js";

import { IconCode, IconMessage } from "@tabler/icons-react";
import { messageWithDefaultSwaps } from "../../../common/entities/messageLibrary.js";
import { userIdFieldRequired } from "../../../common/fields/user_id.js";
import { UserAvatar } from "../Avatars/UserAvatar.js";
import { Weather, WeatherAtLocation } from "../Tools/Weather.js";
import classes from "./MessageDisplay.css.js";

interface MessageDisplayProps {
  message: Message | MessageEntity;
  addToolResult?: (toolCallId: string, result: string) => void;
  streamFromStatic?: boolean;
  focused?: boolean;
  focusable?: boolean;
  read?: boolean;
  user?: UserEntity;
}

interface GuruResult {
  answer: string;
  cards: Array<{
    id: string;
    title: string;
    content: string;
    url: string;
  }>;
}

interface ToolInvocation {
  toolName: string;
  toolCallId: string;
  state: "call" | "result" | "error" | "partial-call";
  args?: Record<string, unknown>;
  result?: string;
  error?: string;
}

export function MessageDisplay({
  message,
  addToolResult,
  streamFromStatic = false,
  focused = false,
  focusable = true,
  user,
}: MessageDisplayProps) {
  const [isCodeExpanded, setIsCodeExpanded] = useState(false);
  const { openPennyChat } = useAside();

  // Helper to determine if message is MessageEntity
  const isMessageEntity = (
    msg: Message | MessageEntity
  ): msg is MessageEntity => {
    return "message" in msg;
  };

  // Helper function to parse calculator result
  const parseCalculatorResult = (result: string | undefined) => {
    if (!result) return { answer: "No result", steps: "" };

    const [answer, ...steps] = result.split("\n");
    return {
      answer,
      steps: steps.join("\n"),
    };
  };

  // Helper function to parse Guru result
  const parseGuruResult = (result: string): GuruResult => {
    try {
      return JSON.parse(result);
    } catch {
      return { answer: result, cards: [] };
    }
  };

  const renderToolInvocation = (invocation: ToolInvocation) => {
    console.log("invocation", invocation);
    switch (invocation.toolName) {
      case "askForConfirmation":
        return (
          <div key={invocation.toolCallId}>
            {String(invocation.args?.message)}
            <div>
              {invocation.state === "result" &&
              invocation.result === "Waiting for user confirmation..." ? (
                <>
                  <button
                    onClick={() =>
                      addToolResult?.(invocation.toolCallId, "Yes")
                    }
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => addToolResult?.(invocation.toolCallId, "No")}
                  >
                    No
                  </button>
                </>
              ) : (
                <b>{invocation.result}</b>
              )}
            </div>
          </div>
        );

      case "guruSearch":
        if (invocation.state === "result") {
          const { answer, cards } = parseGuruResult(invocation.result ?? "");
          return (
            <div key={invocation.toolCallId} className="mt-4 space-y-2">
              <div className="rounded-lg bg-gray-50 p-4 shadow-sm">
                {cards && cards.length > 0 ? (
                  <div className="mt-4">
                    <Tabs defaultValue="answer">
                      <Tabs.List>
                        <Tabs.Tab value="answer">Answer</Tabs.Tab>
                        {cards.map((card) => (
                          <Tabs.Tab key={card.id} value={card.id}>
                            Source: {card.title || "Source Document"}
                          </Tabs.Tab>
                        ))}
                      </Tabs.List>

                      <Tabs.Panel value="answer" p="md">
                        <MarkdownRenderer content={answer} />
                      </Tabs.Panel>

                      {cards.map((card) => (
                        <Tabs.Panel key={card.id} value={card.id} p="md">
                          <Stack>
                            <Text size="lg" fw={700}>
                              {card.title}
                            </Text>
                            <div
                              dangerouslySetInnerHTML={{ __html: card.content }}
                              className="guru-content"
                            />
                          </Stack>
                        </Tabs.Panel>
                      ))}
                    </Tabs>
                  </div>
                ) : (
                  <MarkdownRenderer content={answer} />
                )}
              </div>
            </div>
          );
        }
        if (invocation.state === "call") {
          return (
            <div key={invocation.toolCallId} className="text-sm text-gray-500">
              🔍 Searching internal knowledge base...
            </div>
          );
        }
        if (invocation.state === "error") {
          return (
            <div
              key={invocation.toolCallId}
              className="text-sm text-red-500 p-2 bg-red-50 rounded"
            >
              ❌ Error searching Guru: {invocation.error}
            </div>
          );
        }
        break;

      case "calculator": {
        if (invocation.state !== "result") return null;
        const { answer, steps } = parseCalculatorResult(invocation.result);
        return (
          <Tabs defaultValue="answer" key={invocation.toolCallId}>
            <Tabs.List>
              <Tabs.Tab value="answer">Answer</Tabs.Tab>
              {steps && <Tabs.Tab value="steps">How I Solved It</Tabs.Tab>}
            </Tabs.List>

            <Tabs.Panel value="answer" p="sm">
              <div className={classes.toolResult}>
                <Text size="lg" fw={700} c="blue">
                  {answer}
                </Text>
              </div>
            </Tabs.Panel>

            {steps && (
              <Tabs.Panel value="steps" p="sm">
                <Stack className={classes.toolResult} gap="xs">
                  {steps.split("\n\n").map((step, index) => {
                    if (step.startsWith("Starting with:")) {
                      return (
                        <Text key={index} c="dimmed" fs="italic">
                          {step}
                        </Text>
                      );
                    }
                    if (step.startsWith("Expression is now:")) {
                      return (
                        <Text key={index} c="blue" fw={500}>
                          {step}
                        </Text>
                      );
                    }
                    if (step.startsWith("Solve")) {
                      const [operation, calculation] = step.split("\n");
                      return (
                        <div key={index}>
                          <Text size="sm" fw={500}>
                            {operation}
                          </Text>
                          <Code ml="md" style={{ fontSize: "1.1em" }}>
                            {calculation}
                          </Code>
                        </div>
                      );
                    }
                    return (
                      <Text key={index} size="sm">
                        {step}
                      </Text>
                    );
                  })}
                  <div
                    style={{
                      marginTop: "var(--mantine-spacing-md)",
                      borderTop: "1px solid var(--mantine-color-gray-3)",
                      paddingTop: "var(--mantine-spacing-md)",
                    }}
                  >
                    <Text size="lg" fw={700} c="blue">
                      Final Answer: {answer}
                    </Text>
                  </div>
                </Stack>
              </Tabs.Panel>
            )}
          </Tabs>
        );
      }
      case "quickbooksIsConnected": {
        if (typeof invocation.result === "undefined") {
          return <Loader type="dots" />;
        }
        const token = invocation.result as OrganizationIntegrationTokenEntity;
        return (
          <Group>
            <MarkdownRenderer
              content={
                token
                  ? `Yes, ${
                      INTEGRATION_LABELS[
                        token.integration as keyof typeof INTEGRATION_LABELS
                      ]
                    } was connected on ${new Date(
                      token.updated_at!
                    ).toLocaleDateString()}. You can disconnect or connect to another account by going to the [Apps & Integrations page](/connect/overview).`
                  : `No, ${INTEGRATION_LABELS[INTEGRATION_QUICKBOOKS]} is not connected. You can connect it by going to the [Apps & Integrations page](/connect/overview).`
              }
            />
          </Group>
        );
      }
      case "quickbooksQuery": {
        if (!invocation.result) {
          return <Loader type="dots" />;
        }
        console.dir(invocation);

        // Check if there is any data in the QueryResponse
        const queryResponse = (
          invocation.result as unknown as {
            data: { QueryResponse: Record<string, unknown> };
          }
        ).data?.QueryResponse;
        const hasData =
          queryResponse &&
          Object.keys(queryResponse).some(
            (key) =>
              Array.isArray(queryResponse[key]) && queryResponse[key].length > 0
          );

        // Extract totalCount if available
        const totalCount = (queryResponse?.totalCount as number) || 0;

        const result = (
          invocation.result as unknown as {
            success: boolean;
            dataSummary: string;
            summary: string;
          }
        ).success ? (
          <>
            {hasData && (
              <Tabs.Panel value="data" p="md">
                <MarkdownRenderer
                  content={
                    (
                      invocation.result as unknown as {
                        dataSummary: string;
                      }
                    ).dataSummary
                  }
                />
              </Tabs.Panel>
            )}
            <Tabs.Panel value="summary" p="md">
              <MarkdownRenderer
                content={
                  (
                    invocation.result as unknown as {
                      summary: string;
                    }
                  ).summary
                }
              />
            </Tabs.Panel>
          </>
        ) : (
          <Group>
            <Badge color="red">Error</Badge>
            <Text>
              {(invocation.result as unknown as { error: string }).error}
            </Text>
          </Group>
        );

        return (
          <Stack key={invocation.toolCallId}>
            <Tabs defaultValue="summary">
              <Tabs.List>
                <Tabs.Tab value="summary">Summary</Tabs.Tab>
                {hasData && (
                  <Tabs.Tab value="data">Data ({totalCount})</Tabs.Tab>
                )}
                <Tabs.Tab value="query">How I Solved It</Tabs.Tab>
              </Tabs.List>

              {result}
              <Tabs.Panel value="query" p="md">
                <Group>
                  <Badge>Query</Badge>
                  <Code>{(invocation.args as { query: string }).query}</Code>
                </Group>
              </Tabs.Panel>
            </Tabs>
          </Stack>
        );
      }
      case "getWeather": {
        if (!invocation.result) {
          return <Loader type="dots" />;
        }
        return (
          <Weather
            weatherAtLocation={
              invocation.result as unknown as WeatherAtLocation
            }
          />
        );
      }
      case "teamProfiles": {
        if (!invocation.result) {
          return <Loader type="dots" />;
        }
        return (
          <MarkdownRenderer
            key={invocation.toolCallId}
            content={invocation.result}
          />
        );
      }
      default:
        return typeof invocation.result === "string" ? (
          <MarkdownRenderer
            key={invocation.toolCallId}
            content={String(invocation.result ?? invocation.toolName)}
          />
        ) : (
          <Loader type="dots" />
        );
    }
  };

  const renderMessageContent = () => {
    const { data } = message as MessageEntity & {
      data: { user: UserEntity; organization: OrganizationEntity; item: any };
    };

    // Existing Message type rendering
    const messageContentReact =
      "content" in message
        ? message.content
        : messageWithDefaultSwapsReact(
            message.message!,
            data.user,
            data.organization,
            (data as { text?: string }).text,
            data.item
          );

    const messageContentText = useMemo(() => {
      return "content" in message
        ? message.content
        : messageWithDefaultSwaps(
            message.message!,
            data.user,
            data.organization,
            (data as { text?: string }).text,
            data.item
          );
    }, [message, data]);
    const pendingToolCalls = new Map<string, ToolInvocation>();

    const parsedMessageContent = useMemo(() => {
      return streamFromStatic && messageContentText[1] === ":"
        ? messageContentText
            .split("\n")
            .filter(
              (line) =>
                !line.startsWith("d:") &&
                !line.startsWith("e:") &&
                !line.startsWith("f:")
            )
            .map((line) => {
              if (line.startsWith("9:")) {
                const toolCall = JSON.parse(line.substring(2));
                pendingToolCalls.set(toolCall.toolCallId, toolCall);
                return "";
              } else if (line.startsWith("a:")) {
                const toolCall = JSON.parse(line.substring(2));
                const pendingToolCall = pendingToolCalls.get(
                  toolCall.toolCallId
                );
                if (!pendingToolCall) {
                  return "";
                }
                console.log("toolCall", toolCall);
                if (!("toolInvocations" in message)) {
                  (
                    message as { toolInvocations: ToolInvocation[] }
                  ).toolInvocations = [];
                }
                if (typeof message.id !== "string") {
                  addToolResult?.(toolCall.toolCallId, toolCall.args);
                }
                const foundToolCall = (
                  message as { toolInvocations?: ToolInvocation[] }
                )?.toolInvocations?.find?.(
                  (x) => x.toolCallId === toolCall.toolCallId
                );
                if (foundToolCall) {
                  foundToolCall.state = "result";
                  foundToolCall.result = toolCall.result;
                } else {
                  (
                    message as { toolInvocations?: ToolInvocation[] }
                  ).toolInvocations?.push?.({
                    toolName: pendingToolCall.toolName,
                    toolCallId: pendingToolCall.toolCallId,
                    state: "result",
                    args: pendingToolCall.args,
                    result: toolCall.result,
                  });
                }
                return "";
              }
              if (line.startsWith("a:")) {
                const toolResult = JSON.parse(line.substring(2));
                return toolResult.result;
              }
              if (line.startsWith("0:")) {
                return JSON.parse(line.substring(2));
              }
              return line;
            })
            .join("")
        : messageContentText;
    }, [
      messageContentText,
      streamFromStatic,
      (message as { toolInvocations?: ToolInvocation[] }).toolInvocations,
    ]);

    if (isMessageEntity(message)) {
      return (
        <Box className={classes.chatWrapper}>
          {(message.data as { role?: string })?.role === "assistant" ? (
            <MarkdownRenderer content={parsedMessageContent} />
          ) : (
            <Text size="sm" mb={5}>
              {messageContentReact}
            </Text>
          )}
          {message.timestamp && (
            <Group justify="space-between">
              <Text c="dimmed" size="xs">
                {new Date(message.timestamp).toLocaleString("en-US", {
                  weekday: "long",
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                })}
              </Text>
              <Text
                c="dimmed"
                size="xs"
                title={new Date(message.timestamp).toLocaleString()}
              >
                <ReactTimeAgo
                  date={new Date(message.timestamp)}
                  locale="en-US"
                />
              </Text>
            </Group>
          )}
        </Box>
      );
    }

    return parsedMessageContent ? (
      <Box className={classes.chatWrapper}>
        <MarkdownRenderer content={parsedMessageContent} />
      </Box>
    ) : null;
  };

  return (
    <Box className={classes.chatWrapper}>
      <Group
        align="flex-start"
        gap="sm"
        justify="flex-end"
        style={{ "--group-wrap": "none" }}
      >
        {((message?.data as { role?: string })?.role ??
          (message as Message)?.role) === "assistant" ? (
          <Avatar src="/images/penny.png" title="Penny">
            P
          </Avatar>
        ) : null}
        <Stack
          gap={0}
          className={`${classes.messageDisplay} ${
            focusable && focused ? classes.focused : ""
          } ${
            ((message?.data as { role?: string })?.role ??
              (message as Message)?.role) === "assistant"
              ? classes.botBubble
              : ""
          } ${
            ((message?.data as { role?: string })?.role ??
              (message as Message)?.role) === "user"
              ? classes.userBubble
              : ""
          }`}
        >
          {renderMessageContent()}
          {(
            message as { toolInvocations?: ToolInvocation[] }
          ).toolInvocations?.map(renderToolInvocation)}

          {((message?.data as { role?: string })?.role ??
            (message as Message)?.role) === "assistant" ? (
            <Group gap="sm" style={{ "--group-wrap": "none" }}>
              <ActionIcon size="sm" variant="subtle" onClick={openPennyChat}>
                <IconMessage />
              </ActionIcon>
              <ActionIcon
                size="sm"
                variant="subtle"
                onClick={() => setIsCodeExpanded(!isCodeExpanded)}
              >
                <IconCode />
              </ActionIcon>
            </Group>
          ) : (
            ""
          )}
        </Stack>
        {((message?.data as { role?: string })?.role ??
          (message as Message)?.role) === "user" ? (
          userIdFieldRequired.name in message ? (
            <UserAvatar userId={message[userIdFieldRequired.name]} />
          ) : user ? (
            <UserAvatar user={user} />
          ) : (
            <Avatar>?</Avatar>
          )
        ) : null}
      </Group>

      {/* Debug info section */}
      <Modal opened={isCodeExpanded} onClose={() => setIsCodeExpanded(false)}>
        <pre
          style={{
            overflow: "auto",
            display: "block",
            fontSize: "10px",
            whiteSpace: "pre-wrap",
          }}
        >
          {JSON.stringify(message, null, 2)}
        </pre>
      </Modal>
    </Box>
  );
}
