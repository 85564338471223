import { aiProviderIdField } from "../fields/aiProviderId";
import { archivedAtField } from "../fields/archived_at";
import { createdAtField } from "../fields/created_at";
import { idField } from "../fields/id";
import { nameField } from "../fields/name";
import { slugField } from "../fields/slug";
import { updatedAtField } from "../fields/updated_at";
import { EntityDocs } from "../types/docs";
import {
  entity,
  EntityType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";

// AI Model fields
export const fields = [
  aiProviderIdField,
  archivedAtField,
  createdAtField,
  idField,
  nameField,
  slugField,
  updatedAtField,
] satisfies FieldList;

// AI Model primary key fields
export const primaryKeyFields = [idField] satisfies PrimaryKeyFieldTuple;

// Docs
export const docs: EntityDocs = {
  collection: {
    summary: "List AI models, with provider id, name, and slug fields",
  },
  item: { summary: "Perform operations on an ai model." },
};

export const aiModelEntity = entity({
  api: {
    collectionPath: "ai/models",
    itemPath: "ai/models/[id]",
  },
  docs,
  name: "AI Model",
  fields,
  primaryKeyFields,
  tableName: "ai_models",
});

export type AIModelEntity = EntityType<
  typeof aiModelEntity.fields,
  typeof aiModelEntity.primaryKeyFields
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleAIModel: AIModelEntity = {
  id: 123,
  ai_provider_id: 987,
  name: "GPX 1",
  slug: "gpx-1",
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};
