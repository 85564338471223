import { IntegerField } from "../types/field";

export const INTEGRATION_QUICKBOOKS = 0 as const;

export type IntegrationType = typeof INTEGRATION_QUICKBOOKS;

export const INTEGRATION_LABELS: Record<IntegrationType, string> = {
  [INTEGRATION_QUICKBOOKS]: "Quickbooks",
};

export const integrationField: IntegerField<{
  final: true;
  name: "integration";
  required: true;
}> = {
  label: "Integration",
  final: true,
  name: "integration",
  required: true,
  type: "integer",
};
