import { IntegerField } from "../types/field";

// todo: custom language when viewing self events (?)

export const METRIC_LIBRARY_ID_USER_SIGN_IN = 0 as const;
export const METRIC_LIBRARY_ID_ACTIVE_USERS = 1 as const;
export const METRIC_LIBRARY_ID_ORGANIZATIONS_CREATED = 2 as const;
export const METRIC_LIBRARY_ID_USERS_CREATED = 3 as const;
export const METRIC_LIBRARY_ID_MESSAGES_CREATED = 4 as const;

export type MetricType =
  | typeof METRIC_LIBRARY_ID_USER_SIGN_IN
  | typeof METRIC_LIBRARY_ID_ACTIVE_USERS
  | typeof METRIC_LIBRARY_ID_ORGANIZATIONS_CREATED
  | typeof METRIC_LIBRARY_ID_USERS_CREATED
  | typeof METRIC_LIBRARY_ID_MESSAGES_CREATED;

export const metricField: IntegerField<{
  final: true;
  name: "metric";
  required: true;
}> = {
  label: "Metric",
  final: true,
  name: "metric",
  required: true,
  type: "integer",
};

export type MetricProfile = {
  label: string;
  description: string;
};

/**
 * Metric library for the platform.
 * Default swaps are provided for each metric, so that the metric can be customized to the user and item.
 * The default swaps are:
 * - 'First name' .......... becomes the user's first name
 * - 'Last name' ........... becomes the user's last name
 * - 'Nickname' ............ becomes the user's nickname
 * - 'This user' ........... becomes the user's full name
 * - 'This item' ........... becomes the item's name
 * - 'This organization' ... becomes the organization's name
 */
export const METRIC_LIBRARY: Record<number, MetricProfile> = {
  [METRIC_LIBRARY_ID_USER_SIGN_IN]: {
    label: "User Sign Ins",
    description: "This user has signed in to this organization.",
  },
  [METRIC_LIBRARY_ID_ACTIVE_USERS]: {
    label: "Active Users",
    description: "This user had activity in the specified time period.",
  },
  [METRIC_LIBRARY_ID_ORGANIZATIONS_CREATED]: {
    label: "Organizations Created",
    description: "A organization has been created.",
  },
  [METRIC_LIBRARY_ID_USERS_CREATED]: {
    label: "Users Created",
    description: "A user has been created.",
  },
  [METRIC_LIBRARY_ID_MESSAGES_CREATED]: {
    label: "Messages Created",
    description: "A message has been created.",
  },
};

export const TIME_PERIOD_DAILY = 0 as const;
export const TIME_PERIOD_WEEKLY = 1 as const;
export const TIME_PERIOD_MONTHLY = 2 as const;
export const TIME_PERIOD_YEARLY = 3 as const;

export type TimePeriod =
  | typeof TIME_PERIOD_DAILY
  | typeof TIME_PERIOD_WEEKLY
  | typeof TIME_PERIOD_MONTHLY
  | typeof TIME_PERIOD_YEARLY;
