import { Text } from "@mantine/core";
import { getUserName } from "../../../common/entities/user.js";
import { useAdmin } from "../../contexts/AdminContext.js";
import { AdminLayout } from "../layouts/AdminLayout.js";

export function AdminWelcome() {
  const { user } = useAdmin();

  return (
    <AdminLayout>
      <Text p="xs">Welcome to the admin app, {getUserName(user)}!</Text>
    </AdminLayout>
  );
}
