import { archivedAtField } from "../fields/archived_at";
import { createdAtField } from "../fields/created_at";
import {
  customerIdField,
  customerIdFieldRequired,
} from "../fields/customer_id";
import {
  providerIdField,
  providerIdFieldRequired,
} from "../fields/provider_id";
import { EntityDocs } from "../types/docs";
import { entity, EntityType } from "../types/entity";

// Docs
export const docs: EntityDocs = {
  collection: {
    summary:
      "List organization relationships, which are in the form of customer (or client) and provider (or service provider.",
  },
  item: { summary: "Perform operations on an organization relationship." },
};

export const fields = [
  archivedAtField,
  createdAtField,
  customerIdFieldRequired,
  providerIdFieldRequired,
] as const;

export const primaryKeyFields = [providerIdField, customerIdField] as const;

export const organizationRelationshipEntity = entity({
  api: {
    collectionPath: "organization-relationships",
    itemPath: "organization-relationships/[provider_id]/[customer_id]",
  },
  docs,
  name: "OrganizationRelationship",
  fields,
  primaryKeyFields,
  tableName: "organization_relationships",
} as const);

export type OrganizationRelationshipEntity = EntityType<
  typeof fields,
  typeof primaryKeyFields,
  typeof organizationRelationshipEntity.name
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleOrganizationRelationship: OrganizationRelationshipEntity = {
  archived_at: new Date().toISOString(),
  created_at: new Date().toISOString(),
  customer_id: 765,
  provider_id: 543,
};
