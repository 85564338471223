import { IntegerField } from "../types/field";

export const conversationIdField: IntegerField<{
  final: true;
  name: "conversation_id";
}> = {
  final: true,
  label: "Conversation ID",
  name: "conversation_id",
  type: "integer",
};

export const conversationIdFieldRequired: IntegerField<{
  final: true;
  name: "conversation_id";
  required: true;
}> = {
  ...conversationIdField,
  required: true,
};
