import { AppShell } from "@mantine/core";
import React from "react";

import { useAside } from "../hooks/useAside.js";
import { AppHeader } from "./AppHeader.js";
import { AsideContent } from "./AsideContent.js";
import { Navbar } from "./Navbar/Navbar.js";
interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const { opened } = useAside();

  return (
    <AppShell
      withBorder
      navbar={{
        width: 75,
        breakpoint: "sm",
        collapsed: { mobile: true, desktop: false },
      }}
      header={{ height: 70 }}
      aside={{
        width: 450,
        breakpoint: "md",
        collapsed: {
          mobile: true,
          desktop: !opened,
        },
      }}
    >
      <AppHeader />
      <AppShell.Navbar>
        <Navbar />
      </AppShell.Navbar>
      <AppShell.Main
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </AppShell.Main>
      {opened ? (
        <AppShell.Aside p={0}>
          <AsideContent />
        </AppShell.Aside>
      ) : null}
    </AppShell>
  );
};
