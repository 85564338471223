import { IntegerField } from "../types/field";

export const parentMessageIdField: IntegerField<{
  final: true;
  name: "parent_message_id";
}> = {
  final: true,
  label: "Parent Message ID",
  name: "parent_message_id",
  type: "integer",
};
