import {
  ActionIcon,
  Group,
  Menu,
  ScrollArea,
  Table,
  Text,
} from "@mantine/core";
import { IconDots, IconX } from "@tabler/icons-react";
import { getUserName, UserEntity } from "../../../common/entities/user.js";
import { UserAvatar } from "../Avatars/UserAvatar.js";

interface UsersTableProps {
  users: UserEntity[];
  onDelete?: (user: UserEntity) => Promise<void>;
}

export function UsersTable({ users, onDelete }: UsersTableProps) {
  const rows = users.map((user) => (
    <Table.Tr key={user.id}>
      <Table.Td pl="lg">
        <Group gap="sm">
          <UserAvatar user={user} size={45} radius={80} />
          <div>
            <Text fw={700}> {getUserName(user)}</Text>
            <Text fz="sm" c="dimmed">
              Member since{" "}
              {new Date(user.created_at ?? Date.now()).toLocaleDateString(
                undefined,
                {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                }
              )}
            </Text>
          </div>
        </Group>
      </Table.Td>
      <Table.Td>
        {/* <Badge color={user.active ? "green" : "gray"}>
          {user.active ? "Active" : "Inactive"}
        </Badge> */}
      </Table.Td>
      <Table.Td>{user.email}</Table.Td>
      <Table.Td>
        <Menu position="bottom-end" withArrow>
          <Menu.Target>
            <ActionIcon variant="subtle" color="gray">
              <IconDots style={{ width: "70%", height: "70%" }} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              leftSection={<IconX size={14} />}
              color="red"
              onClick={() => onDelete?.(user)}
            >
              Remove
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <ScrollArea mah={`calc(100vh - 139px)`}>
      <Table verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th pl="lg">User</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Phone</Table.Th>
            <Table.Th />
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </ScrollArea>
  );
}
