import { StringField } from "../types/field";

export const statsField: StringField<{
  name: "stats";
  required: true;
}> = {
  label: "Stats",
  name: "stats",
  required: true,
  type: "string",
};
