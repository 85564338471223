import { Anchor } from "@mantine/core";

import {
  defaultMessageSwapsReact,
  getMessageLibraryItemWithSwapsReact,
} from "../../../common/entities/messageLibrary.js";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { getUserName, UserEntity } from "../../../common/entities/user.js";

export function messageWithDefaultSwapsReact(
  messageId: number,
  user: UserEntity,
  organization: OrganizationEntity,
  text?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref0?: any
) {
  return getMessageLibraryItemWithSwapsReact(
    messageId,
    defaultMessageSwapsReact(
      user,
      user ? (
        <Anchor fw={500} key="--link-user" href={`/users/${user.id}`}>
          {getUserName(user)}
        </Anchor>
      ) : null,
      organization,
      organization ? (
        <Anchor
          fw={500}
          key="--link-organization"
          href={`/organizations/${organization.id}`}
        >
          {organization.name}
        </Anchor>
      ) : null,
      text,
      ref0,
      ref0 ? (
        <Anchor fw={500} key="--link-item" href={`/items/${ref0.id}`}>
          {ref0.name}
        </Anchor>
      ) : null
    )
  );
}
