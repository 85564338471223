import { Box, Loader, NavLink, Title } from "@mantine/core";
import { useEffect, useState } from "react";

import { NavLink as ReactRouterNavLink } from "react-router-dom";
import { ConversationEntity } from "../../../common/entities/conversation.js";
import { conversationClient } from "../../../common/utils/entityClient.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { clientError } from "../../utils/clientError.js";

function groupConversations(conversations: ConversationEntity[]) {
  const today = new Date();
  const oneDayAgo = new Date(today);
  oneDayAgo.setDate(today.getDate() - 1);
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const oneYearAgo = new Date(today);
  oneYearAgo.setFullYear(today.getFullYear() - 1);

  const todayConversations: ConversationEntity[] = [];
  const last7DaysConversations: ConversationEntity[] = [];
  const last30DaysConversations: ConversationEntity[] = [];
  const lastYearConversations: ConversationEntity[] = [];

  conversations.forEach((conversation) => {
    const updatedAt = new Date(conversation.updated_at!);
    if (updatedAt >= oneDayAgo) {
      todayConversations.push(conversation);
    } else if (updatedAt >= sevenDaysAgo) {
      last7DaysConversations.push(conversation);
    } else if (updatedAt >= thirtyDaysAgo) {
      last30DaysConversations.push(conversation);
    } else if (updatedAt >= oneYearAgo) {
      lastYearConversations.push(conversation);
    }
  });

  return {
    todayConversations,
    last7DaysConversations,
    last30DaysConversations,
    lastYearConversations,
  };
}

export function PennyConversationList() {
  const { customer, serviceProvider } = useActiveStates();
  const [conversations, setConversations] = useState<ConversationEntity[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function load() {
      // todo: add pagination
      const conversationsResponse = await conversationClient(clientError).list({
        provider_id: serviceProvider?.id,
        customer_id: customer?.id,
      });
      if (conversationsResponse.success) {
        setConversations(
          conversationsResponse.data.success
            ? (await Promise.all(conversationsResponse.data.data)).reverse()
            : []
        );
      }
      setIsLoading(false);
    }
    load().catch((e) => console.error(e));
  }, [customer?.id, serviceProvider?.id]);

  const {
    todayConversations,
    last7DaysConversations,
    last30DaysConversations,
    lastYearConversations,
  } = groupConversations(conversations);

  return (
    <>
      {isLoading ? (
        <Box
          style={{
            alignContent: "flex-end",
            alignItems: "center",
            display: "flex",
            height: "fit-content",
            justifyContent: "center",
            overflowY: "auto",
            padding: "100px",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <>
          {todayConversations.length > 0 && (
            <Title order={6} p="sm">
              Today
            </Title>
          )}
          {todayConversations.map((conversation) => (
            <NavLink
              component={ReactRouterNavLink}
              key={conversation.id}
              to={`/penny/conversations/${conversation.id}`}
              label={conversation.title}
            />
          ))}

          {last7DaysConversations.length > 0 && (
            <Title order={6} p="sm">
              Previous 7 days
            </Title>
          )}
          {last7DaysConversations.map((conversation) => (
            <NavLink
              component={ReactRouterNavLink}
              key={conversation.id}
              to={`/penny/conversations/${conversation.id}`}
              label={conversation.title}
            />
          ))}

          {last30DaysConversations.length > 0 && (
            <Title order={6} p="sm">
              Previous 30 days
            </Title>
          )}
          {last30DaysConversations.map((conversation) => (
            <NavLink
              component={ReactRouterNavLink}
              key={conversation.id}
              to={`/penny/conversations/${conversation.id}`}
              label={conversation.title}
            />
          ))}

          {lastYearConversations.length > 0 && (
            <Title order={6} p="sm">
              Previous year
            </Title>
          )}
          {lastYearConversations.map((conversation) => (
            <NavLink
              component={ReactRouterNavLink}
              key={conversation.id}
              to={`/penny/conversations/${conversation.id}`}
              label={conversation.title}
            />
          ))}

          {conversations.length === 0 && <p>No conversations</p>}
        </>
      )}
    </>
  );
}
