import React, { useState } from "react";

import { AsideContext } from "./AsideContext.js";

export const AsideProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [opened, setOpened] = useState(false);

  const openPennyChat = () => setOpened(true);
  const closeAside = () => setOpened(false);

  return (
    <AsideContext.Provider value={{ opened, openPennyChat, closeAside }}>
      {children}
    </AsideContext.Provider>
  );
};
