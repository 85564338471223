import { Group, Loader, Stack, Tooltip } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { OrganizationAvatar } from "../../components/Avatars/OrganizationAvatar.js";
import { useAdmin } from "../../contexts/AdminContext.js";
import { AdminMainMenu } from "../menus/AdminMainMenu.js";

export function AdminToolBar() {
  const { organization } = useAdmin();

  if (!organization) {
    return <Loader size="xs" />;
  }

  return (
    <Group
      bg="var(--mantine-color-org_primary-9)"
      justify="space-between"
      p="xs"
      style={{ borderBottom: "1px solid var(--mantine-color-org_primary-7)" }}
    >
      <Group gap="xs" p={0}>
        <Link to="/">
          <OrganizationWithTooltip organization={organization} />
        </Link>
        <AdminMainMenu />
      </Group>
    </Group>
  );
}

class OrganizationWithTooltip extends React.Component<{
  organization: OrganizationEntity;
}> {
  public organization: OrganizationEntity;

  constructor(props: { organization: OrganizationEntity }) {
    super(props);
    this.organization = props.organization;
  }

  render(): React.ReactNode {
    return (
      <Tooltip
        label={`${this.organization.name} Admin`}
        position="right"
        withArrow
        transitionProps={{ duration: 0 }}
        zIndex={301}
      >
        <Stack align="center" gap={0}>
          <OrganizationAvatar
            organization={this.organization}
            style={{ borderRadius: 0, position: "relative", top: "1px" }}
          />
        </Stack>
      </Tooltip>
    );
  }
}
