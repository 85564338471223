import { useAuth } from "../../app/hooks/useAuth";
import {
  EntityFilterType,
  EntityListOptionsType,
  EntityServiceResult,
} from "../../services/entityService";
import { aiModelEntity } from "../entities/aiModel";
import { aiProviderEntity } from "../entities/aiProvider";
import { conversationEntity } from "../entities/conversation";
import { messageEntity } from "../entities/message";
import { metricEntity } from "../entities/metric";
import { messagesLastReadEntity } from "../entities/messagesLastRead";
import { organizationEntity } from "../entities/organization";
import { organizationRelationshipEntity } from "../entities/organizationRelationship";
import { organizationRelationshipUserEntity } from "../entities/organizationRelationshipUser";
import { organizationUserEntity } from "../entities/organizationUser";
import { threadEntity } from "../entities/thread";
import { userEntity } from "../entities/user";
import {
  Entity,
  EntityCreateType,
  EntityPrimaryKeyType,
  EntityType,
  EntityUpdateType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";
import { network } from "./network";

function entityClient<T extends FieldList, U extends PrimaryKeyFieldTuple>(
  entity: Entity<T, U>,
  onError: (error: string) => void
) {
  const auth = useAuth();
  const accessToken = auth.session?.accessToken;

  function replaceIds(path: string, ids: EntityPrimaryKeyType<U>) {
    let finalPath = path;
    for (const index in entity.primaryKeyFields) {
      const id = ids[index];
      finalPath = finalPath.replace(
        `[${entity.primaryKeyFields[index].name}]`,
        typeof id === "number" ? (id as number).toString(10) : String(id)
      );
    }
    return finalPath;
  }

  return {
    create: async (data: EntityCreateType<T, U>) => {
      return network.post<EntityServiceResult<EntityType<T, U>>>(
        "/api/" + entity.api.collectionPath,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
        onError
      );
    },
    list: async <
      V extends FieldList = FieldList,
      W extends PrimaryKeyFieldTuple = PrimaryKeyFieldTuple
    >(
      filters: EntityFilterType<T, U> = {},
      options: EntityListOptionsType<T, U, V, W> = {}
    ) => {
      return network.get<EntityServiceResult<EntityType<T, U>[]>>(
        "/api/" + entity.api.collectionPath,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            filters: Object.keys(filters).length
              ? JSON.stringify(filters)
              : undefined,
            options: Object.keys(options).length
              ? JSON.stringify(options)
              : undefined,
          },
        },
        onError
      );
    },
    item: (...ids: EntityPrimaryKeyType<U>) => {
      const itemPath = replaceIds("/api/" + entity.api.itemPath, ids);
      return {
        delete: async () => {
          return network.delete<EntityServiceResult<EntityType<T, U>>>(
            itemPath,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
            onError
          );
        },
        get: async () => {
          return network.get<EntityServiceResult<EntityType<T, U>>>(
            itemPath,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
            onError
          );
        },
        update: async (data: Partial<EntityUpdateType<T, U>>) => {
          return network.patch<EntityServiceResult<EntityType<T, U>>>(
            itemPath,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            },
            onError
          );
        },
        upsert: async (data: Partial<EntityUpdateType<T, U>>) => {
          return network.put<EntityServiceResult<EntityType<T, U>>>(
            itemPath,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            },
            onError
          );
        },
      };
    },
  };
}

// Be created, 'O' entity clients, and serve ye well the purpose of connecting front end
// user interface to the api for each entity and prosper.

export const aiModelClient = (onError: (error: string) => void) =>
  entityClient(aiModelEntity, onError);

export const aiProviderClient = (onError: (error: string) => void) =>
  entityClient(aiProviderEntity, onError);

export const conversationClient = (onError: (error: string) => void) =>
  entityClient(conversationEntity, onError);

export const messageClient = (onError: (error: string) => void) =>
  entityClient(messageEntity, onError);

export const metricClient = (onError: (error: string) => void) =>
  entityClient(metricEntity, onError);

export const messagesLastReadClient = (onError: (error: string) => void) =>
  entityClient(messagesLastReadEntity, onError);

export const organizationClient = (onError: (error: string) => void) =>
  entityClient(organizationEntity, onError);

export const organizationRelationshipClient = (
  onError: (error: string) => void
) => entityClient(organizationRelationshipEntity, onError);

export const organizationRelationshipUserClient = (
  onError: (error: string) => void
) => entityClient(organizationRelationshipUserEntity, onError);

export const organizationUserClient = (onError: (error: string) => void) =>
  entityClient(organizationUserEntity, onError);

export const threadClient = (onError: (error: string) => void) =>
  entityClient(threadEntity, onError);

export const userClient = (onError: (error: string) => void) =>
  entityClient(userEntity, onError);

/** Example usage * /
async function example() {
  const createResponse = await organizationClient.create({
    name: "New Organization",
    entitlements: [],
    initials: "NO",
    slug: "new-organization",
    description: "This is a new organization",
  });

  if ("data" in createResponse && createResponse.data) {
    console.log(createResponse.data);
  }

  const organization = await organizationClient.item(1).get();

  if ("data" in organization && organization.data) {
    console.log(organization.data);
  }

  const updatedOrganization = await organizationClient.item(1).update({
    name: "Updated Organization",
  });

  if ("data" in updatedOrganization && updatedOrganization.data) {
    console.log(updatedOrganization.data);
  }

  const deletedOrganization = await organizationClient.item(1).delete();

  if (deletedOrganization.success) {
    console.log("Organization deleted");
  }

  const organizations = await organizationClient.list();

  if ("data" in organizations && organizations.data) {
    console.log(organizations.data);
  }
}
/**/
