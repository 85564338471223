import { Avatar, AvatarProps } from "@mantine/core";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { PrimaryColor } from "../../theme.js";
import { getInitials } from "../../../common/utils/textUtils.js";
import createStyles from "./OrganizationAvatar.css.js"; // Import the function

export function OrganizationAvatar({
  organization,
  hasShadow = false,
  ...props
}: { organization: OrganizationEntity; hasShadow?: boolean } & AvatarProps) {
  const { bg, color, ...rest } = props;
  const styles = createStyles(organization.theme_primary_color ?? "#228BE6"); // Call the function with the color

  return (
    <div className={hasShadow ? styles.avatarWrapper : ""}>
      <Avatar
        alt={`${organization.name} Profile Picture`}
        bg={organization.picture_background_color || bg || PrimaryColor}
        color={organization.theme_primary_color || color || PrimaryColor}
        fw={900}
        name={organization.initials || getInitials(organization.name ?? "-")}
        src={organization.picture}
        variant="filled"
        {...rest}
      />
    </div>
  );
}
