import {
  ActionIcon,
  Group,
  Loader,
  Menu,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { IconDots, IconPencil, IconTrash } from "@tabler/icons-react";
import { useState } from "react";

import {
  organizationEntity,
  OrganizationEntity,
} from "../../../common/entities/organization.js";
import { OrganizationRelationshipEntity } from "../../../common/entities/organizationRelationship.js";
import { OrganizationAvatar } from "../Avatars/OrganizationAvatar.js";
import { OrganizationModal } from "../Modals/OrganizationModal.js";

export type OrgRelationship = OrganizationRelationshipEntity & {
  [organizationEntity.name]: OrganizationEntity;
};
interface OrganizationsTableProps {
  organizations: OrgRelationship[];
  onDelete?: (organization: OrgRelationship) => void;
  onEdit?: (organization: OrgRelationship) => void;
  isDeletingCustomerId: number | null;
}

export function OrganizationsTable({
  organizations: organizationRelationships,
  onDelete,
  onEdit,
  isDeletingCustomerId,
}: OrganizationsTableProps) {
  const [editingOrganization, setEditingOrganization] =
    useState<OrgRelationship | null>(null);
  const [modalOpened, setModalOpened] = useState(false);

  const handleEdit = (relationship: OrgRelationship) => {
    setEditingOrganization(relationship);
    setModalOpened(true);
  };

  const handleEditSubmit = async (updatedOrg: Partial<OrganizationEntity>) => {
    if (onEdit && editingOrganization) {
      await onEdit({ ...editingOrganization, ...updatedOrg });
    }
    setModalOpened(false);
    setEditingOrganization(null);
  };

  const rows = organizationRelationships.map((relationship) => (
    <Table.Tr key={relationship.Organization.id}>
      <Table.Td pl="lg">
        <Group gap="sm">
          <OrganizationAvatar
            variant="filled"
            size={45}
            radius="sm"
            organization={relationship.Organization}
            color={relationship.Organization.theme_primary_color ?? "#228BE6"}
          />
          <Stack gap={0}>
            <Text fw={700}>{relationship.Organization.name}</Text>
            <Text fz="sm" c="dimmed">
              Client since{" "}
              {new Date(
                relationship.Organization.created_at ?? Date.now()
              ).toLocaleDateString(undefined, {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </Text>
          </Stack>
        </Group>
      </Table.Td>
      <Table.Td>{relationship.Organization.primary_domain}</Table.Td>
      <Table.Td>{relationship.Organization.primary_location}</Table.Td>
      <Table.Td>
        {relationship.customer_id === isDeletingCustomerId ? (
          <Group gap="xs" justify="center">
            <Loader size="xs" />
            Removing
          </Group>
        ) : (
          <Menu position="bottom-end" withArrow>
            <Menu.Target>
              <ActionIcon variant="subtle" color="gray">
                <IconDots style={{ width: "70%", height: "70%" }} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                leftSection={<IconPencil size={14} />}
                onClick={() => handleEdit(relationship)}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                leftSection={<IconTrash size={14} />}
                color="red"
                onClick={() => onDelete?.(relationship)}
              >
                Remove
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Table verticalSpacing="sm">
        <Table.Thead
          style={{
            position: "sticky",
            top: "70px",
            zIndex: 1,
          }}
        >
          <Table.Tr
            style={{
              backgroundColor: "var(--mantine-color-dark-7)",
              borderBottom: "none",
              boxShadow: "inset 0 -1px 0 0 var(--mantine-color-dark-4)",
            }}
          >
            <Table.Th pl="lg">Organization</Table.Th>
            <Table.Th>Domain</Table.Th>
            <Table.Th>Location</Table.Th>
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>

      <OrganizationModal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setEditingOrganization(null);
        }}
        onSubmit={handleEditSubmit}
        editMode={true}
        initialData={editingOrganization?.Organization ?? undefined}
      />
    </>
  );
}
