import { Divider } from "@mantine/core";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { OrganizationRelationshipUserManagement } from "../../components/Admin/OrganizationRelationshipUserManagement.js";
import { UserManagement } from "../../components/Admin/UserManagement.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";

export function PeopleOverview() {
  const { customer, serviceProvider, isCustomerUser } = useActiveStates();

  // Use customer if available, otherwise use service provider
  const activeOrganization: OrganizationEntity | undefined = customer?.id
    ? customer
    : serviceProvider;

  const isCustomerProviderRelationshipView =
    customer &&
    serviceProvider &&
    activeOrganization.id === customer.id &&
    activeOrganization.id !== serviceProvider.id;

  return (
    <Layout>
      <PageTitle title="Organization Profile" />

      <UserManagement organizationId={activeOrganization.id!} />
      {isCustomerProviderRelationshipView ? (
        <>
          <Divider />
          <OrganizationRelationshipUserManagement
            serviceProvider={serviceProvider}
            customer={customer}
            isCustomerUser={isCustomerUser}
          />
        </>
      ) : null}
    </Layout>
  );
}
