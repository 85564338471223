import { IntegerField } from "../types/field";

export const botIdField: IntegerField<{
  final: true;
  name: "bot_id";
}> = {
  final: true,
  label: "Bot ID",
  name: "bot_id",
  type: "integer",
};

export const botIdFieldRequired: IntegerField<{
  final: true;
  name: "bot_id";
  required: true;
}> = {
  ...botIdField,
  required: true,
};
