import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Group,
  Menu,
  Stack,
  Text,
} from "@mantine/core";
import { IconDots } from "@tabler/icons-react";
import { useCallback, useMemo, useState } from "react";

import { notifications } from "@mantine/notifications";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { useAuth } from "../../hooks/useAuth.js";
import { AccountPrimaryColor } from "../../theme.js";
import { StoredAuthProfile } from "../../utils/storeAuthProfile.js";
import { UserAvatar } from "../Avatars/UserAvatar.js";
import { EditProfileModal } from "../Modals/EditProfileModal.js";
import { UserEntity } from "../../../common/entities/user.js";

export const AccountMenu = ({
  allAccounts,
  updateAccount,
}: {
  allAccounts: StoredAuthProfile[];
  updateAccount: (account: StoredAuthProfile) => void;
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const { sessionId, logOut: _logOut, logOutAll: _logOutAll } = useAuth(); // Get sessionId from useAuth
  const { serviceProvider, isCustomerUser, customer, setUser } =
    useActiveStates();

  const logOut = useCallback(async () => {
    setUser(undefined);
    const storedProfiles = localStorage.getItem("authProfiles");
    if (storedProfiles) {
      try {
        const parsedProfiles: StoredAuthProfile[] = JSON.parse(storedProfiles);
        localStorage.setItem(
          "authProfiles",
          JSON.stringify(
            parsedProfiles.filter((x) => x.sessionId !== sessionId)
          )
        );
      } catch (e) {
        console.error("Error removing stored profile", e);
      }
    }
    await _logOut((url) => window.location.replace(url));
  }, [_logOut, sessionId, setUser]);

  const logOutAll = useCallback(async () => {
    setUser(undefined);
    try {
      // Remove all stored profiles from localStorage
      localStorage.removeItem("authProfiles");

      // Clear sessionStorage to remove session data
      sessionStorage.clear();

      // Call the logout function with a redirect
      await _logOutAll((url) => window.location.replace(url));
    } catch (error) {
      console.error("Error logging out of all accounts:", error);
    }
  }, [_logOutAll, setUser]);

  const account = useMemo(
    () => allAccounts.find((a) => a.sessionId === sessionId),
    [allAccounts, sessionId]
  );

  /**
   * Handles switching between accounts by setting the appropriate session ID
   * and updating the ActiveStatesContext.
   *
   * @param account - The account to switch to.
   */
  const handleSwitch = (account: StoredAuthProfile) => {
    if (account.sessionId === undefined) {
      throw new Error("account has no session");
    }
    // Real account: set sessionId in sessionStorage
    sessionStorage.setItem("sessionId", account.sessionId.toString(10));
    notifications.show({
      autoClose: 500000,
      color: "green",
      message: "Switching session, please wait...",
      position: "bottom-center",
      title: "Switching",
    });
    window.location.replace("/");
  };

  /**
   * Renders each account as a Menu.Item with appropriate Avatar styling.
   *
   * @param accountsToRender - Array of accounts to render.
   * @returns Array of JSX elements representing each account.
   */
  const renderAccounts = (accountsToRender: StoredAuthProfile[]) =>
    accountsToRender.map((account) => {
      return (
        <Menu.Item
          key={account.sessionId}
          onClick={() => handleSwitch(account)}
          leftSection={
            <UserAvatar
              user={account.profile}
              color={AccountPrimaryColor}
              variant="filled"
              size={45}
              alt={
                account.profile.given_name ||
                account.profile.nickname ||
                account.profile.email ||
                "Unknown"
              }
              radius="xl"
            />
          }
          rightSection={<Badge color={AccountPrimaryColor}>{2}</Badge>}
        >
          <Stack mr="xl" gap={0}>
            <Group>
              <Text fw={700}>
                {" "}
                {account.profile.given_name ||
                  account.profile.nickname ||
                  account.profile.email ||
                  "Unknown"}
              </Text>

              <Badge
                variant="light"
                size="xs"
                radius="sm"
                color={AccountPrimaryColor}
              >
                Admin
              </Badge>
            </Group>

            <Text size="sm" fw={500}>
              {account.profile.email}
            </Text>
          </Stack>
        </Menu.Item>
      );
    });

  /**
   * Determines the Avatar content and styling for the Menu.Target.
   *
   * @returns JSX Element representing the Avatar.
   */
  const renderMenuTargetAvatar = () => {
    if (!account) {
      // Handle case when there's no active account
      return (
        <UserAvatar
          user={{
            email: "Unknown",
          }}
          radius="xl"
          size={35}
        />
      );
    }

    return (
      <UserAvatar
        user={account.profile}
        radius="xl"
        size={35}
        color={AccountPrimaryColor}
        variant="filled"
        src={account.profile.picture}
      />
    );
  };

  const handleEditProfileClick = () => {
    setMenuOpened(false);
    setIsEditModalOpen(true);
  };

  return (
    <>
      <Menu shadow="md" opened={menuOpened} onChange={setMenuOpened}>
        <Menu.Target>
          <Group gap={0}>
            {renderMenuTargetAvatar()}
            <Stack gap={0} mt="6" ml="xs" mr="md">
              <Text size="sm" fw={700} mt={-8}>
                {account?.profile.given_name ||
                  account?.profile.nickname ||
                  account?.profile.email ||
                  "Unknown"}
              </Text>
              <Text mt={-2} size="xs">
                {account?.profile.email || "No Email"}
              </Text>
            </Stack>
            <ActionIcon variant="default" size="lg">
              <IconDots size={20} />
            </ActionIcon>
          </Group>
        </Menu.Target>

        <Menu.Dropdown pt={0}>
          <Card radius="lg">
            <Card.Section h={90} bg={AccountPrimaryColor}>
              <Text ta="center" mt="lg" c="white" size="sm" fw={600}>
                {isCustomerUser
                  ? customer?.primary_domain
                  : serviceProvider?.primary_domain}
              </Text>
            </Card.Section>
            <UserAvatar
              user={account?.profile ?? {}}
              color={AccountPrimaryColor}
              variant="filled"
              size={80}
              radius={80}
              mx="auto"
              mt={-30}
              style={{ border: "2px solid white" }}
            />
            <Text ta="center" fz="lg" fw={700} mt="sm">
              {account?.profile.given_name ||
                account?.profile.nickname ||
                account?.profile.email ||
                "Unknown"}
            </Text>
            <Text ta="center" fz="sm" fw={500} c="dimmed">
              {account?.profile.email || "No Email"}
            </Text>
            <Group justify="center">
              <Button
                color={AccountPrimaryColor}
                size="xs"
                mt="sm"
                variant="light"
                onClick={handleEditProfileClick}
              >
                Edit Profile
              </Button>

              <Button
                color={AccountPrimaryColor}
                onClick={logOut}
                size="xs"
                mt="sm"
                variant="light"
              >
                Log out
              </Button>
            </Group>
          </Card>
          {allAccounts.length > 1 && (
            <>
              <Menu.Divider />
              <Menu.Label>Other accounts</Menu.Label>
              {renderAccounts(
                allAccounts.filter(
                  (account) => account.profile.id !== account?.profile.id
                )
              )}
            </>
          )}
          <Menu.Divider />
          <Menu.Item component="a" href="/api/auth/login">
            Add new account
          </Menu.Item>
          <Menu.Item onClick={logOutAll}>Log out of all accounts</Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <EditProfileModal
        opened={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        activeProfile={account}
        onSave={(user: UserEntity) => {
          updateAccount({
            ...(account as { sessionId: number }),
            profile: {
              ...user,
            },
          });
        }}
      />
    </>
  );
};
