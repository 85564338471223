import { css } from "./utils/css.js";

export default css`
  html,
  body {
    overflow-x: hidden;
  }
  div.!mantine-ColorInput-section {
    pointer-events: none !important;
  }
`;
